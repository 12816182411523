

export const CreateCaseInvoiceDefaultValue = () => {
 
    return {
        is_subsidiary_client : false,
        school_id : ""
    }
  
}

export default CreateCaseInvoiceDefaultValue