import { useFieldArray } from "react-hook-form";
import InputLabel from "../../../components/InputLabel/InputLabel";

export default function OptionsFieldArray({ register, control,errors,index }) {

    const {
        fields: OptionsFields,
        append: OptionsAppend,
        remove: OptionsRemove,
    } = useFieldArray({
        control,
        name: `fields.${index}.options`,
    });

    const onClickAddOption = () => {
        OptionsAppend({
            name:""
        });
    }

    return (
        <div>
            {OptionsFields.map((item, optionsindex) => {
                return (
                    <div key={item.id} className={`${optionsindex !==0 ? "mt-2" : ""}`}>
                        <div className="flex items-center w-full">
                            <InputLabel labelTitle={"Option Name"}
                                inputId={`fields.${index}.options.${optionsindex}.name`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Option Name"}
                                classNameInput="w-full"
                                name={`fields.${index}.options.${optionsindex}.name`}
                                register={register}
                                classNameLabel={"text-[#60657B] w-full opacity-50 text-xs font-normal"}
                            />
                            {optionsindex >= 1 && <button type="button" className="w-8 h-[42px] mt-6 rounded border border-[#E4E6F2] text-[#60657B]" onClick={() => OptionsRemove(optionsindex)}>
                                X
                            </button>}
                        </div>
                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.fields?.[index]?.options?.[optionsindex]?.name?.message}</p>}
                        {optionsindex === 0 && 
                        <section className="mt-1">
                            <button
                                className="text-[#2B2D38] opacity-50 text-base"
                                type="button"
                                onClick={onClickAddOption}
                            >
                                + Add Option
                            </button>
                        </section>}
                    </div>
                );
            })}
        </div>
    )
}