import classNames from "../../utils/utils"

export default function Select({ title, selectData, value, className, classNameTitle, onChange, selectClass, register, name, disable, indexx,required }) {
    return (
        <div className={classNames("flex  rounded w-full", selectClass)}>
            {title && <p className={classNames("font-[system-ui]", classNameTitle)}>{title}</p>}
            <div className="w-full">
                <select
                    {...register(`${name}`,required)}
                    id="location"
                    className={classNames(!disable && ` bg-[white]`, className)}
                    value={value}
                    onChange={onChange}
                    disabled={disable}
                >
                    {selectData?.map((e, index) => (
                        <option key={index}
                            disabled={indexx > 0 ? disable?.includes(e?.id) : false}
                            value={e?.id} className="cursor-pointer font-[system-ui]">{e?.name ?? e?.contactName}</option>
                    ))}
                </select>
            </div>
        </div >
    )
}
