import * as yup from "yup";



export const RequestErrorEditRequestForm = () => {
    const formSchemaForOptions = {
        name:yup.string().required('This field is required')
    }

    const formSchemaForFields = {
        label: yup.string().required('This field is required'),
        type: yup.string().required('This field is required'),
        // options:yup.array().of(yup.object().shape(formSchemaForOptions))
        // options: yup.array().when('type', {
        //     is: (val) => val === 'checkbox', // Only require options if type is 'checkbox'
        //     then: schema => schema.of(yup.object().shape(formSchemaForOptions)),
        //     otherwise: schema => schema
        // })
      };

    return (
        yup.object().shape({
            title: yup.string().required("This field is required"),
            fields:yup.array().of(yup.object().shape(formSchemaForFields))
        })
    )
}

export default RequestErrorEditRequestForm


