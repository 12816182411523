import classNames from '../../utils/utils';

export default function InputLabel({
  labelTitle,
  maxLength,
  inputId,
  disabled,
  onChange,
  min,
  max,
  type,
  onKeyPress,
  placeholder,
  value,
  className,
  classNameInput,
  defaultValue,
  register,
  classNameLabel,
  name,
  serviceList,
  required,
}) {
  return (
    <div className={classNames(`flex flex-col ${serviceList ? 'gap-[0px]' : 'gap-2'}`, classNameInput)}>
      <label htmlFor={inputId} className={classNames('font-[system-ui]', classNameLabel)}>
        {labelTitle}
      </label>
      <input
        className={classNames('outline-none rounded border py-1.5 px-4 font-[system-ui] ', className)}
        id={inputId}
        type={type}
        max={max}
        min={min}
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onKeyPress={onKeyPress}
        {...register(name, required)}
      />
    </div>
  );
}
