import React from "react";
import { useFieldArray } from "react-hook-form";

export default function UseFieldArrayServiceTypes({  control, register, errors }) {

    const { fields, append, remove } = useFieldArray(
        {
            control,
            name: "service_types"
        }
    );
    const onClickAddActivity =() => {
        append({});
    }
   
    return (
        <div>
            <ul className="flex flex-col gap-2 mt-8 mb-4">
                {fields.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <li className="bg-[#FAFAFD] p-1 flex justify-between items-center">
                                <input
                                    className="w-4/5 h-10 pl-2 bg-inherit outline-0 font-[system-ui]"
                                    placeholder="Service Type"
                                    {...register(`service_types.[${index}].name`)}
                                />
                                { <button type="button" className="w-8 h-8 rounded border border-[#E4E6F2] text-[#60657B]" onClick={() => remove(index)}>
                                    X
                                </button>}
                            </li>
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.service_types?.[index]?.name?.message}</p>}
                        </div>
                    );
                })}
            </ul>
            <section>
                <button
                    className="text-[#2B2D38] opacity-50 text-base"
                    type="button"
                    onClick={onClickAddActivity}
                >
                    + Add Service Type
                </button>
            </section>
        </div>
    );
}