import { $authHost } from "..";

export default class EmployeeDashboardHttp {
  static employeeServicesLinks() {
    return $authHost.get(`/employee/my/services`);
  };
  static employeeAssignmentsLinks() {
    return $authHost.get(`/employee/my/assignments`);
  };
  static employeeCasesLinks() {
    return $authHost.get(`/employee/myCases`);
  };
}
