import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

export default function CurrencyInputNew({
  name,
  setValue,
  classNameLabel,
  labelTitle,
  className,
  defaultValue,
  placeholder,
  disabled,
  prefix="$ ",
  decimalSeparator="."
}) {
  const [value1, setValue1] = useState(defaultValue ? Number(defaultValue).toFixed(2) : null);

  const handleChange = e => {
    const parsedValue = e?.replace(/[^\d.]/gi, '');
    setValue1(parsedValue);
  };

  const handleOnBlur = () => {
    Number(value1) ? setValue1(Number(value1).toFixed(2)) : setValue1('');
  };

  useEffect(() => {
    setValue(name, value1 ? Number(value1).toFixed(2) : '');
  }, [value1]);

  useEffect(() => {
    defaultValue ? setValue1(Number(defaultValue).toFixed(2)) : setValue1(null);
  }, [defaultValue]);

  return (
    <div className="">
      <p className={classNameLabel}>{labelTitle}</p>
      <CurrencyInput
      // decimalSeparator={decimalSeparator}
        prefix={prefix}
        className={className}
        data-number-to-fixed="2"
        data-number-stepfactor="100"
        value={value1}
        placeholder={placeholder}
        onValueChange={handleChange}
        onBlur={handleOnBlur}
        allowDecimals
        decimalsLimit="2"
        disabled={disabled}
        disableAbbreviations
        // maxLength={5}
      />
    </div>
  );
}
