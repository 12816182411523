export const columnsCases = (onClickEdit, onClickRemove, onClickEmployeeList) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.id}</p>,
  },
  {
    accessorKey: 'employees',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => (
      <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() => onClickEmployeeList(row?.original?.['to_employees'])}>
        {row?.original?.['to_employees']?.map(
          (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'client',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.client}</p>,
  },
  {
    accessorKey: 'Service',
    header: 'Service', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => 
    // <p className="whitespace-nowrap">{row?.original?.service}</p>
    <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() => onClickEmployeeList(row?.original?.['services'],"For which services is this Assignment/Case")}>
        {row?.original?.['services']?.map(
          (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ,
    
  },
  {
    accessorKey: 'Service Type',
    header: 'Service Type', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    // Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.service_types}</p>,
    Cell: ({ row }) => (
      <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() => onClickEmployeeList(row?.original?.['service_types'],"For which service types is this Assignment/Case")}>
        {row?.original?.['service_types']?.map(
          (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'Case name',
    header: 'Case Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: 'Status',
    header: 'Status', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    // Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.status == '0' ? 'Inactive' : 'Active'}</p>,
    Cell: ({ row }) => (
      <span
        className={` ${
          row?.original?.status == 1
            ? 'bg-[#F0FCFD] text-[#00B1B2] ring-[#C4EAD9]'
            : 'bg-gray-50 text-gray-600 ring-gray-500/10'
        }
     inline-flex items-center rounded-md 
      ring-1 ring-inset  px-2 py-1 text-xs font-medium
     `}>
        {row?.original?.status == 1 ? 'Active'  : 'Inactive'}
      </span>
    ),
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 120, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        <img
          onClick={() => onClickEdit(row?.original?.id)}
          className="cursor-pointer"
          src={'/EditIcon.png'}
          alt="EditIcon"
        />
        <img
          src={row?.original?.haveLogs ? '/RemoveSVGDissabled.svg' : '/RemoveSVG.svg'}
          alt="Remove"
          onClick={() => onClickRemove(row?.original?.['id'], row?.original?.haveLogs)}
          className="cursor-pointer"
        />
      </div>
    ),
  },
];
