import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "../Header/Header";
import Sidebar from '../Sidebar/Sidebar'



export default function Layout() {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        
        location.pathname == "/Employee" ? navigate("/Employee/Dashboard") : location.pathname == "/SuperAdmin" ? navigate("/SuperAdmin/Dashboard"): location.pathname == "/Admin" ? navigate('/Admin/Dashboard')
        :  location.pathname  == "/Client" && navigate('/Client/ServiceLog')
    }, [])

    return (
        <div className="">
            <Header />
            <div className='flex mx-auto drop-shadow-[4px_0px_10px_rgba(131, 133, 136, 0.24)]'>
                <div className='min-w-[18%] main:min-w-[16%] hidden lg:block z-50 shadow-sidebar '>
                    <Sidebar />
                </div>
                <div className="mt-[58px] w-full overflow-hidden">
                <Outlet />
                </div>
            </div>
        </div>
    )
}
