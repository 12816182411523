

export const AddServiceLogAdminDefaultValue = (clientsData,servicesData) => {
    const StartDate = new Date()
    StartDate.setMinutes("00")
    StartDate.setHours("10")
    StartDate.setSeconds('00');

    const EndDate = new Date()
    EndDate.setMinutes("00")
    EndDate.setHours("11")
    EndDate.setSeconds('00');

    return {
        school_id: clientsData?.[0]?.id,
        service_id: servicesData?.[0]?.id,
        serviceLogTimeActivities: [{
            start_time: StartDate,
            end_time: EndDate,
            activiteee : {},
            position_id:"",
            service_type_id:"",
            new_case_id:"",
        }],
    }
}

export default AddServiceLogAdminDefaultValue
