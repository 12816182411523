import { useEffect, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { notifySuccess } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsCasesSlice, getAllClientsSlice, loadParentsCasesSlice, loadParentsSlice } from '../../redux/clientsSlice/clientsSlice';
import Select from '../../components/Select/Select';
import InvoiceHttp from '../../http/invoiceHttp/invoiceHttp';
import DatePicker from 'react-multi-date-picker';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import { columnsSendInvoicesAdmin } from './helpers/SendInvoicesCaseUtils';

import 'react-toastify/dist/ReactToastify.css';

export default function SendInvoicesCase() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const clientsData = useSelector(state => state?.clients?.storeAllClientsCasesData);
  const allParents = useSelector(state => state?.clients?.parentClientCasesSliceDataAll?.data);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    setError,
  } = useForm({});
  const navigate = useNavigate();

  const [isError, setIsError] = useState();
  const [allParentsSelectData, setAllParentsSelectData] = useState();
  const [allParentsClients, setAllParentsClients] = useState([]);
  const [currentParentId, setCurrentParentId] = useState('');
  const [currentSchoolId, setCurrentSchoolId] = useState();
  const [tbodyData, setTbodyData] = useState([]);
  const [checked, setChecked] = useState([]);

  const afterSubmit = () => {
    notifySuccess('Invoices successfully sent');
    setTimeout(() => {
      navigate('/Admin/Invoices');
    }, 2000);
  };
  
  const onSubmit = data => {
    setIsLoading(true);
    const sendedData = {
      school_id: data?.school_id || '',
      group_school_id: data?.group_school_id || '',
      selectedIds: checked,
    };
    InvoiceHttp.sendInvoices(sendedData)
      .then(() => afterSubmit())
      .catch(err => {
        setIsError(err?.response?.data?.message);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };
  const OnClickCancle = () => {
    navigate('/Admin/Invoices');
  };

  const onChangeSchoolId = e => {
    setCurrentSchoolId(e.target.value);
    setValue('school_id', e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const onChangeParentSchoolId = e => {
    setCurrentSchoolId('');
    setValue('school_id', '');
    setCurrentParentId(e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const handleDateChange = value => {
    const date = {
      startDate: value?.[0]?.format('MM/DD/YYYY'),
      endDate: value?.[1]?.format('MM/DD/YYYY'),
    };
    setDateRange(date);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const handleMasterCheckboxChange = e => {
    const isChecked = e.target.checked;
    const allCheckboxIds = tbodyData.map(item => ({ id: item.id })); // Assuming your data has unique IDs
    if (isChecked) {
      setChecked(allCheckboxIds);
      tbodyData?.length > 0 && tbodyData?.map(item => setValue(`${Number(item?.id)}`, true));
    } else {
      setChecked([]);
      tbodyData?.length > 0 && tbodyData?.map(item => setValue(`${Number(item?.id)}`, false));
    }
  };

  useEffect(() => {
    dispatch(getAllClientsCasesSlice());
    dispatch(loadParentsCasesSlice());
  }, []);

  useEffect(() => {
    reset({ school_id: currentSchoolId, group_school_id: currentParentId });
    setValue('school_id', currentSchoolId);
    setValue('group_school_id', currentParentId);
  }, [tbodyData]);

  useEffect(() => {
    setValue('school_id', '');
    setCurrentSchoolId('');
  }, [clientsData]);

  useEffect(() => {
    (currentParentId || currentSchoolId) && dateRange?.startDate && dateRange?.endDate
      ? InvoiceHttp.getInvoicesByClientIdCase(currentSchoolId, dateRange, currentParentId).then(e =>
          setTbodyData(e?.data?.data),
        )
      : setTbodyData([]);
  }, [currentSchoolId, dateRange, currentParentId]);

  useEffect(() => {
    const dataAllParents = allParents?.map(item => ({
      name: item?.['Client Name'],
      id: item?.id,
    }));
    dataAllParents?.unshift({ id: '', name: 'Not Selected' });
    setAllParentsSelectData(dataAllParents);
  }, [allParents]);

  useEffect(() => {
    currentParentId &&
      ClientHttp.getClientsByParent(currentParentId).then(e => {
        const data = e?.data?.data;
        data?.unshift({ id: '', name: 'Not Selected' });
        setAllParentsClients(data);
        setValue('school_id', '');
        setCurrentSchoolId('');
      });
  }, [currentParentId]);

  useEffect(() => {
    if (tbodyData?.length) {
      checked?.length === tbodyData?.length ? setValue('masterCheckbox', true) : setValue('masterCheckbox', false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, tbodyData]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Send Invoices Per Assignment/Case'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          {allParentsSelectData && (
            <div className="w-full bg-white sm:px-8 px-2">
              <Select
                register={register}
                title={'Select Parent Company'}
                selectData={allParentsSelectData}
                selectClass="flex-col gap-2"
                onChange={onChangeParentSchoolId}
                name={'group_school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_school_id?.message}</p>}
            </div>
          )}

          <div className="py-4 sm:px-7 px-2 w-full flex gap-6">
            {currentParentId == '' ? (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={clientsData}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            ) : (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={allParentsClients}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            )}
          </div>
          <div className="py-4 sm:px-7 px-2 w-full flex flex-col gap-2">
            <p className="text-[#60657B] opacity-50 text-xs">{'Date Range'}</p>
            <div className="w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[38px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none">
              <DatePicker range format="MM/DD/YYYY" placeholder="MM/DD/YYYY - MM/DD/YYYY" onChange={handleDateChange} />
            </div>
          </div>
          <div>
            <div className="px-4 py-1 sm:m-4 mx-4 my-6 bg-white rounded-xl">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={tbodyData ?? []}
                    columns={columnsSendInvoicesAdmin(
                      register,
                      handleMasterCheckboxChange,
                      tbodyData,
                      checked,
                      setChecked,
                    )}
                    maxHeight="500px"
                  />
                </div>
              </div>
            </div>
          </div>
          {isError && <p className="text-base font-medium text-[red] py-4 sm:px-7 px-2">{isError}</p>}
          <div>
            <CancleAdd
              disabled={isLoading || checked?.length < 1}
              buttonText={`Send Invoices`}
              onClickCancle={OnClickCancle}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
