
export const EditServiceDefaultValue = form => {
  return {
    formFields: form?.fields?.map(({ id, name, label, type, options }) => ({
      id: id,
      name: name,
      label: label,
      type: type,
      value : "",
      options:
        type === 'checkbox'
          ? options?.map((elem) => ({
            name: elem?.name,
            // a:true
            }))
          : null,
    })),
  };
};

export default EditServiceDefaultValue;
