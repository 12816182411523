import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function TotalTime({ control, setErrorss, setDiableAdd, data, isOverTime }) {
  const [isError, setIsError] = useState(false);
  const errorTime = useSelector(state => state?.error?.error);
  const isOverTime2 = data?.eligibleOvertime == 1 ? true : false;
  const maxTime = isOverTime || isOverTime2 ? 1440 : 480;
  const serviceLogTimeActivitiesList = useWatch({ control, name: `serviceLogTimeActivities` });
  const sum = serviceLogTimeActivitiesList.map(elem => elem?.totalMin)?.reduce((a, b) => a + b);

  useEffect(() => {
    setErrorss(sum > maxTime);
    setIsError(sum > maxTime);
    setDiableAdd(sum > maxTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceLogTimeActivitiesList, isOverTime]);

  return (
    <div>
      {!isNaN(sum) && (
        <p className="text-lg font-semibold">
          TOTAL {`${Math.floor(sum / 60)}`}h {`${sum % 60}`}m
        </p>
      )}
      {isError && (
        <p className="text-base font-medium text-[red]">
          {isOverTime || isOverTime2 ? 'Max total work time can be 24h 0m' : 'Max total work time can be 8h 0m'}
        </p>
      )}
      {errorTime && <p className="text-base font-medium text-[red]">Time Match Error</p>}
    </div>
  );
}
