import classNames from '../../../utils/utils';

export const columnsEmployee = (onClickEmployeeList, EditEmployeesLink) => [
  {
    accessorKey: 'name',
    header: 'Employee Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: `position`,
    // id: 'name',
    header: 'Position', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.position}</p>,
  },
  {
    accessorKey: `department`,
    // id: 'name',
    header: 'Department', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.department}</p>,
  },
  {
    accessorKey: `type`,
    // id: 'name',
    size: 60,
    header: 'Type', //uses the default width from defaultColumn prop
    Cell: ({ row }) => (
      <p className="whitespace-nowrap">
        {' '}
        {row?.original?.type == '1'
          ? 'Full Time'
          : row?.original?.type == '2'
          ? 'Part Time'
          : row?.original?.type == ''
          ? ''
          : 'Contract'}
      </p>
    ),
  },
  {
    accessorKey: `wage`,
    // id: 'name',
    header: 'Wage', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.wage}</p>,
    maxSize: 60,
  },
  {
    accessorKey: `start date`,
    // id: 'name',
    header: 'Start Date', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['start date']}</p>,
    maxSize: 80,
  },
  {
    accessorFn: row => `${row?.['client assignments']}`,
    header: 'Client Assignments', //uses the default width from defaultColumn prop
    size: 120,
    Cell: ({ row }) =>
      row?.original?.['client assignments']?.length > 0 && (
        <li
          className={`list-disc whitespace-nowrap text-ellipsis whitespace-nowrap min-w-[60px] overflow-hidden sm:text-sm text-xs font-medium text-gray-900 ${
            row?.original?.['client assignments']?.length > 1 && 'cursor-pointer'
          }`}
          onClick={() =>
            row?.original?.['client assignments']?.length > 1 &&
            onClickEmployeeList(row?.original?.['client assignments'], row?.original?.['name'])
          }>
          {row?.original?.['client assignments']?.[0]?.name}{' '}
          {row?.original?.['client assignments']?.length > 1 &&
            `(and ${row?.original?.['client assignments']?.length - 1} more)`}
        </li>
      ),
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'In Proccess' : row?.original?.status == '2' ? 'Active' : 'Inactive'}
      </p>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 70, //decrease the width of this column
    maxSize: 70,
    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          minWidth: '70px',
        }}>
          <img
          onClick={() => EditEmployeesLink(row?.original?.id,)}
          className="cursor-pointer w-[24px] h-[24px]"
          src={'/EditIcon.png' }
          alt="EditIcon"
        />
        {/* <button
          className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
          onClick={() => EditEmployeesLink(row?.original?.id)}>
          Edit
        </button> */}
      </div>
    ),
  },
];

export const columnsEmployeeInternal = EditNonbillableEmployeesLink => [
  {
    accessorKey: 'name',
    header: 'Employee Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: `position`,
    // id: 'name',
    header: 'Position', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.position}</p>,
  },
  // {
  //     accessorKey: `department`,
  //     // id: 'name',
  //     header: 'Department', //uses the default width from defaultColumn prop
  //     Cell: ({ row }) => (
  //         <p className="whitespace-nowrap">{row?.original?.department}</p>
  //     ),
  // },
  {
    accessorKey: `type`,
    // id: 'name',
    size: 60,
    header: 'Type', //uses the default width from defaultColumn prop
    Cell: ({ row }) => (
      <p className="whitespace-nowrap">
        {' '}
        {row?.original?.type == '1'
          ? 'Full Time'
          : row?.original?.type == '2'
          ? 'Part Time'
          : row?.original?.type == ''
          ? ''
          : 'Contract'}
      </p>
    ),
  },
  {
    accessorKey: `wage`,
    // id: 'name',
    header: 'Wage', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.wage}</p>,
    maxSize: 60,
  },
  {
    accessorKey: `start date`,
    // id: 'name',
    header: 'Start Date', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['start date']}</p>,
    maxSize: 80,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'In Proccess' : row?.original?.status == '2' ? 'Active' : 'Inactive'}
      </p>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 70, //decrease the width of this column
    maxSize: 70,
    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          minWidth: '70px',
        }}>
        <button
          className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
          onClick={() => EditNonbillableEmployeesLink(row?.original?.id)}>
          Edit
        </button>
      </div>
    ),
  },
];
