import { useFieldArray, useForm } from 'react-hook-form';
import Title from '../../components/Title/Title';
import { useEffect, useMemo, useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import DatePickerTime from '../../components/DatePickerTime/DatePickerTime';
import EditUnbillableHoursDefaultValue from './EditUnbillableHoursDefaultValue';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import { useNavigate, useParams } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import TimerDifference from '../../components/TimerDifference/TimerDifference';
import { yupResolver } from '@hookform/resolvers/yup';
import { RequestErrorEditUnbillableHours } from './RequestErrorEditUnbillableHours';
import TotalTime from '../AddServiceLog/TotalTime';
import { useSelector } from 'react-redux';
import unbillableHoursHttp from '../../http/ubillableHoursHttp/unbillableHoursHttp';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';

import 'react-toastify/dist/ReactToastify.css';

export default function EditUnbillableHours() {
  const errorTime = useSelector(state => state?.error?.error);
  const employeeData = useSelector(state => state?.adminProfile?.employeeProfiledata);
  const [errorss, setErrorss] = useState(false);
  const [diableAdd, setDiableAdd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [errorWhenAprrove, setErrorWhenAprrove] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: useMemo(() => EditUnbillableHoursDefaultValue(data), [data]),
    resolver: yupResolver(RequestErrorEditUnbillableHours()),
  });

  const {
    fields: ServiceLogTimeActivitiesFields,
    append: ServiceLogTimeActivitiesAppend,
    remove: ServiceLogTimeActivitiesRemove,
  } = useFieldArray({
    control,
    name: 'serviceLogTimeActivities',
  });

  const afterSubmit = () => {
    
    notifySuccess('Non-Service hour successfully changed');
    setTimeout(() => {
      navigate('/Employee/UnbillableHours');
    }, 2000);
  };

  const onSubmit = data => {
    setIsLoading(true);
    data.serviceLogTimeActivities = data.serviceLogTimeActivities.map(elem => ({
      id: elem?.id,
      activity: elem?.activity,
      start_time:
        typeof elem?.start_time === 'string' && !elem?.start_time?.includes('GMT')
          ? elem?.start_time
          : elem?.start_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      end_time:
        typeof elem?.end_time === 'string'
          ? elem?.end_time
          : elem?.end_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
    }));
    unbillableHoursHttp
      .updateubillableServiceLogLinks(params?.id, data)
      .then(() => afterSubmit())
      .catch(err =>
        err?.response?.status == '403' ? setErrorWhenAprrove('You can not change Approved Service Log') : '',
        setIsLoading(false)
      );
  };

  const onClickAddNewTimeActivity = lastTime => {


    // const Startdate = new Date(lastTime);
    // Startdate.setMinutes(Startdate.getMinutes());
    // Startdate.setHours(Startdate.getHours());
    // Startdate.setSeconds('00');
    // const Enddate = new Date(lastTime);
    // Enddate.setMinutes(Enddate.getMinutes());
    // Enddate.setHours(Enddate.getHours()+1);
    // Enddate.setSeconds('00');

    const Startdate = new Date(lastTime);
    Startdate.setMinutes(Startdate.getMinutes());
    Startdate.setHours(Startdate.getHours());
    Startdate.setSeconds('00');
    const Enddate = new Date(lastTime);
    Enddate.setMinutes(Startdate.getMinutes() == '45' ? '00' : Enddate.getMinutes() + 15);
    Enddate.setHours(Startdate.getMinutes() == '45' ? Enddate.getHours() + 1 : Enddate.getHours());
    Enddate.setSeconds('00');

    ServiceLogTimeActivitiesAppend({
      start_time: Startdate,
      end_time: Enddate,
    });
  };


  const afterDelete = () => {
    setIsLoading(true);
    notifySuccess('Non-Service hour successfully Deleted');
    setTimeout(() => {
      navigate('/Employee/UnbillableHours');
    }, 2000);
  };

  const onClickCancle = () => navigate('/Employee/UnbillableHours');

  const onClickDelete = () => {
    setIsOpen(true);
  };

  const onClickYes = id => {
    unbillableHoursHttp.deleteCurrentubillableServiceLogLinks(id).then(() => afterDelete());
    setIsOpen(false);
  };

  useEffect(() => {
    unbillableHoursHttp.currentubillableServiceLogLinks(params?.id).then(e => {
      setData(e?.data?.data);
    });
  }, []);

  useEffect(() => {
    reset(EditUnbillableHoursDefaultValue(data));
  }, [data]);

  const more4Hour = watch('serviceLogTimeActivities')?.some(e => e?.currentMinError === true);
  const min15Step = watch('serviceLogTimeActivities')?.some(e => e?.currentStepMinError === true);
  const activitesCount = watch('serviceLogTimeActivities');

  useEffect(() => {
    activitesCount?.length == 1 && setDiableAdd(false);
    activitesCount?.length == 1 && setErrorss(false);
  }, [activitesCount]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <CompanyFormModal
          open={isOpen}
          setOpen={setIsOpen}
          onClickYes={onClickYes}
          companyFormId={params?.id}
          text="Are you sure you want to delete this Non-Service hour"
        />
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={employeeData?.type == 1 ? 'Edit Service Log' : 'Edit Non-Service Hours'}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
            <div className="w-full bg-white">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'Date'}
                register={register}
                name={'date'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.date?.message}</p>}
            </div>
          </div>
          {ServiceLogTimeActivitiesFields.map((elem, index) => (
            <div key={elem?.id} className="grid sm:px-7 px-4 grid-cols-1 gap-2 mt-5">
              <div className="flex flex-row gap-2 md:w-1/2 md:pr-2">
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="Start Time"
                    setValue={setValue}
                    value={getValues(`serviceLogTimeActivities.${index}.start_time`)}
                    name={`serviceLogTimeActivities.${index}.start_time`}
                    control={control}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.start_time?.message}
                    </p>
                  )}
                </div>
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="End Time"
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.end_time`}
                    control={control}
                    value={getValues(`serviceLogTimeActivities.${index}.end_time`)}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.end_time?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="mt-2">
                  <InputLabel
                    classNameInput="flex flex-col"
                    classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                    className="w-full border p-[7px]"
                    labelTitle={'Activity'}
                    placeholder={'Activity'}
                    register={register}
                    name={`serviceLogTimeActivities.${index}.activity`}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.activity?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <TimerDifference
                  setErrorss={setErrorss}
                  setValue={setValue}
                  index={index}
                  nameStart={`serviceLogTimeActivities.${index}.start_time`}
                  nameEnd={`serviceLogTimeActivities.${index}.end_time`}
                  control={control}
                />
              </div>
              <>
                {index !== 0 && index == ServiceLogTimeActivitiesFields.length - 1 && (
                  <TotalTime control={control} index={index} setErrorss={setErrorss} setDiableAdd={setDiableAdd} />
                )}
              </>
              <div className="flex justify-end">
              <section
                className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] cursor-pointer ${
                  (diableAdd || errorss || more4Hour) && `bg-[#A6ADC9]`
                } `}
                onClick={() =>
                  onClickAddNewTimeActivity(
                    getValues(
                      `serviceLogTimeActivities.${getValues(`serviceLogTimeActivities`)?.length - 1}.end_time`,
                    ),
                  )}
                >
                <button
                  className={`text-[#4885ED] text-sm font-normal ${
                    (diableAdd || errorss || more4Hour) && `text-[white]`
                  }`}
                  type="button"
                  disabled={diableAdd || errorss || more4Hour}
                  >
                  Add New Time Activity
                </button>
              </section>
            </div>
              {ServiceLogTimeActivitiesFields.length > 1 && (
                <div className="flex flex-col gap-6">
                  <div className="flex justify-end">
                    <section
                      className="border rounded px-3 py-1  text-center min-w-[204px] max-w-[204px]"
                      onClick={() => {
                        ServiceLogTimeActivitiesRemove(index);
                      }}>
                      <button className="text-[#4885ED] text-sm font-normal" type="button">
                        Remove Time Activity
                      </button>
                    </section>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="sm:px-7 px-4 mt-5">
            {errorWhenAprrove && <p className="mb-0 text-sm text-amber-800">{errorWhenAprrove}</p>}
          </div>
          <div className="mt-6">
            <CancleAdd
              buttonText={'Save'}
              onClickCancle={onClickCancle}
              disabled={errorss || errorTime || more4Hour || min15Step || isLoading}
              isDelete={true}
              onClickDelete={onClickDelete}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
