

export const AddUnbillableHoursDefaultValue = () => {
    const Startdate = new Date()
    Startdate.setMinutes("00")
    Startdate.setHours("10")
    Startdate.setSeconds('00');

    const Enddate = new Date()
    Enddate.setMinutes("00")
    Enddate.setHours("11")
    Enddate.setSeconds('00');

    return {
        serviceLogTimeActivities: [{
            start_time: Startdate,
            end_time: Enddate,
            activity : "",
        }],
    }
}

export default AddUnbillableHoursDefaultValue
