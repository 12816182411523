import { useEffect, useState } from 'react';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import Pagination from '../../components/Pagination/Pagination';
import TableClients from '../../components/DataTable/TableClients';
import { columnsCompanyFormsEmployee } from './helpers/CompanyFormsEmployeeUtils';

export default function CompanyFormsEmployee() {
  const [companyFormList, setCompanyFormList] = useState([]);
  const [companyFormListPage, setCompanyFormListPage] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCompanyForms = async (pageId = '') => {
    setLoading(true);
    try {
      const response = await companyFormsHttp.getAllCompanyFormsEmployee(pageId);
      setCompanyFormList(response?.data?.data || []);
      setCompanyFormListPage(response?.data || {});
    } catch (err) {
      console.error('Error fetching company forms:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyForms();
  }, []);

  const handlePaginationClick = (id) => {
    fetchCompanyForms(id);
  };

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <TitleBlok textItem="My Forms" className="text-base font-semibold" src="/CompanyFormsIcon.svg" />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className={`w-full overflow-x-auto  ${loading && `animate-pulse`}`}>
            <div className="inline-block min-w-full">
              <TableClients tbody={companyFormList} columns={columnsCompanyFormsEmployee()} maxHeight="500px" />
            </div>
        </div>
        <Pagination onPaginationClick={handlePaginationClick} paginationData={companyFormListPage?.links} />
      </div>
    </div>
  );
}
