import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import InfoListModal from '../../compo../../components/Modal/InfoListModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsRequestForms } from './helpers/RequestFormsUtils';
import requestHttp from '../../http/requestHttp/requestHttp';

export default function RequestForms() {
  const navigate = useNavigate();
  const [requestsList, setRequestsList] = useState([]);
  const [requestsListPage, setRequestsListDataPage] = useState();
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();

  useEffect(() => {
    requestHttp.getRequestForms('').then(e => {
      setRequestsListDataPage(e?.data);
      setRequestsList(e?.data?.data);
    });
  }, [newLoad]);

  const onPaginationClick = id => {
    requestHttp.getRequestForms(id).then(e => {
      setRequestsListDataPage(e?.data);
      setRequestsList(e?.data?.data);
    });
  };

  const onClickRemove = id => {
    setIsOpen(true);
    setCompanyFormId(id);
  };

  const onClickYes = id => {
    requestHttp.deleteRequestForm(id).then(e => e.status === 200 && setNewLoad(!newLoad));
    setIsOpen(false);
  };

  const onClickEmployeeList = list => {
    if (list?.length > 1) {
      setIsOpenEmployee(true);
      const namesArray = list.map(item => item.name);
      setList(namesArray);
    }
  };

  const onClickEdit = id => {
    navigate(`/Admin/EditRequestForm/${id}`);
  };

  const onClickAssignClients = (id, title, clients) => {
    navigate(`/Admin/AssignRequestFormToClients/${id}`, { state: { title: title, clients: clients } });
  };

  return (
    <div className="w-full sm:p-8 p-2">
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={companyFormId}
        text="Are you sure you want to delete this Project Form ?"
      />
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={'For which Clients is this Company Form'}
      />
      <TitleBlok src={'/CompanyFormsIcon.svg'} textItem={'Request Forms'} className="text-base font-bold">
        <div className="flex sm:gap-4 gap-2">
          <Button
            onClick={() => navigate('/Admin/CreateRequestForm')}
            buttonText={'Create Request Form'}
            className={'text-[#fff] sm:text-sm text-xs'}
          />
        </div>
      </TitleBlok>
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={requestsList ?? []}
              columns={columnsRequestForms(onClickEdit, onClickRemove, onClickAssignClients, onClickEmployeeList)}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={requestsListPage?.links} />
      </div>
    </div>
  );
}
