import { FaArrowUpLong, FaArrowDownLong } from 'react-icons/fa6';

export const columnsServiceLogAdmin = (setIsDesc,isDesc,onClickDownload,onClickViewRegular) => [
  {
    accessorKey: 'week of',
    // header: 'Week Of', //uses the default width from defaultColumn prop
    Header: ({ row }) => (
      <div className="relative flex items-start " onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
        <div className=" text-sm leading-6 ">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold cursor-pointer">
            {'Week Of'}
          </label>
        </div>
        <div className="flex h-6 items-center ml-1">{isDesc === 'desc' ? <FaArrowDownLong /> :  <FaArrowUpLong />}</div>
      </div>
    ),
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['week of']}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employee']}</p>,
  },
  {
    accessorKey: 'service_to',
    header: 'Service To', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['client_name']}</p>,
  },
  {
    accessorKey: 'partner',
    header: 'Service Providers', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['partner'] ?? ''}</p>,
  },
  // {
  //     accessorKey: 'hours',
  //     header: 'Hours', //uses the default width from defaultColumn prop
  //     // hishem avelacnem
  //     size: 40,
  //     Cell: ({ row }) => (
  //         <p className="whitespace-nowrap">{row?.original?.["hours"]}</p>
  //     ),
  // },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 80, //decrease the width of this column
    Cell: ({ row }) => (
      <div className="flex gap-2 min-w-[80px]">
        <button
          onClick={() =>
            onClickDownload(
              row?.original?.id,
              row?.original?.employee_id,
              row?.original?.['status'],
              row?.original?.partner_id,
              row?.original?.['partner'],
              row?.original?.['client_name'],
              row?.original?.['employee'],
              row?.original?.['week of'],
              row?.original?.['client_id'],
            )
          }
          className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          Download
        </button>
        <button
          onClick={() =>
            onClickViewRegular(
              row?.original?.id,
              row?.original?.employee_id,
              row?.original?.['status'],
              row?.original?.partner_id,
              row?.original?.['partner'],
              row?.original?.['client_name'],
              row?.original?.['employee'],
              row?.original?.['week of'],
              row?.original?.['client_id'],
            )
          }
          className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          View
        </button>
      </div>
    ),
  },
];

export  const columnsServiceLogAdminCustom = (setIsDesc,isDesc,onClickDownloadCustom,onClickViewCustom,onClickEmployeeList) => [
    {
      accessorKey: 'Date',
      // header: 'Date', //uses the default width from defaultColumn prop
      Header: ({ row }) => (
        <div
          className="relative flex items-start "
          onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
          <div className=" text-sm leading-6 ">
            <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold cursor-pointer">
              {'Date'}
            </label>
          </div>
          <div className="flex h-6 items-center ml-1">
            {isDesc === 'desc' ? <FaArrowUpLong /> : <FaArrowDownLong />}
          </div>
        </div>
      ),
      size: 80,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['date']}</p>,
    },
    {
      accessorKey: 'employee',
      header: 'Employee',
      Cell: ({ row }) =>  <p
      className="whitespace-nowrap cursor-pointer"
      onClick={() => onClickEmployeeList(row?.original?.['employees'],"For which employees is this Custom Log")}
      >
      { row?.original?.['employees']?.map(
        (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
      )}
    </p>,
    },
    {
      accessorKey: 'service_to',
      header: 'Service To',
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['client']}</p>,
    },
    {
      accessorKey: 'partner',
      header: 'Service Providers',
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['partner'] ?? ''}</p>,
    },
    {
      accessorKey: 'Action',
      header: 'Action',
      size: 80,
      Cell: ({ row }) => (
        <div className="flex gap-2 min-w-[80px]">
          <button
            onClick={() =>
              onClickDownloadCustom(
                row?.original?.id,
                row?.original?.employee_id,
                row?.original?.['status'],
                row?.original?.partner_id,
                row?.original?.['partner'],
                row?.original?.['client'],
                row?.original?.['employee'],
                row?.original?.['date'],
                row?.original?.['client_id'],
              )
            }
            className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
            Download
          </button>
          <button
            onClick={() =>
              onClickViewCustom(
                row?.original?.id,
                row?.original?.employee_id,
                row?.original?.['status'],
                row?.original?.partner_id,
                row?.original?.['partner'],
                row?.original?.['client'],
                row?.original?.['employee'],
                row?.original?.['date'],
                row?.original?.['client_id'],
              )
            }
            className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
            View
          </button>
        </div>
      ),
    },
  ];