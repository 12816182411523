import { useEffect, useState } from 'react';
import classNames from '../../utils/utils';
import Select from 'react-select';

export default function MultiSelect({
  title,
  selectData,
  className,
  classNameTitle,
  selectClass,
  register,
  name,
  setValue,
  defalutValue,
  setSelectedEmployees,
  All = 'All Employees',
}) {
  const [current, setCurrent] = useState('');

  const hasAllValue = current && current.some(item => item.value === 'All');

  const onChangeMultiSelect = e => {
    setCurrent(e);
    if (e.some(item => item.value === 'All')) {
      const result = selectData.map(({ value }) => (value === 'All' ? null : { id: value })).filter(Boolean);
      setValue(name, result);
      setSelectedEmployees && setSelectedEmployees(result);
    } else
      setValue(
        name,
        e.map(({ value }) => ({ id: value })),
      );
    setSelectedEmployees && setSelectedEmployees(e.map(({ value }) => ({ id: value })));
  };

  // function arraysEqual(arr1, arr2) {
  //   // Check if the arrays have the same length
  //   // if (arr1?.length !== arr2?.length) {
  //   //     return false;
  //   // }
  //   const filteredArray = arr2.filter(obj => obj.value !== 'All');
  //   // Sort the arrays
  //   arr1.sort();
  //   filteredArray.sort();
  //   // Iterate over the arrays and compare each object
  //   for (let i = 0; i < arr1.length; i++) {
  //     // Use JSON.stringify to compare objects as strings
  //     if (JSON.stringify(arr1[i]) !== JSON.stringify(filteredArray[i])) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  function arraysEqual(arr1, arr2) {
    const filteredArray1 = arr1
      .filter(obj => obj.value !== 'All')
      ?.map(e => e?.value)
      .sort((a, b) => a - b)
      ?.join('');
    const filteredArray2 = arr2
      .filter(obj => obj.value !== 'All')
      ?.map(e => e?.value)
      .sort((a, b) => a - b)
      ?.join('');

    if (filteredArray1 === filteredArray2) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    defalutValue?.length && selectData?.length && arraysEqual(defalutValue, selectData)
      ? setCurrent([{ value: 'All', label: All }])
      : setCurrent(defalutValue);
    if(selectData?.length <= 1){
      setCurrent([])
    }
  }, [defalutValue, selectData]);
  


  return (
    <div className={classNames('flex rounded w-full ', selectClass)}>
      <p className={classNameTitle}>{title}</p>
      <div className="w-full">
        <Select
          className={className}
          isMulti
          options={selectData}
          onChange={onChangeMultiSelect}
          classNamePrefix="select"
          value={hasAllValue ? [{ value: 'All', label: All }] : current || []} // Use empty array if no value
          isOptionDisabled={e => (hasAllValue ? true : false)}
        />
      </div>
    </div>
  );
}
