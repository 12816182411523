export const EditUnbillableHoursDefaultValue = data => {
  return {
    date: data?.date?.slice(0, 10),
    serviceLogTimeActivities: data?.['time activities']?.map((e, index) => {
      const Startdate = new Date();
      Startdate.setHours(
        e?.['Start Time']?.includes('pm')
          ? +`${e?.['Start Time']?.match(/\d+/)?.[0]}` == 12
            ? `${e?.['Start Time']?.match(/\d+/)?.[0]}`
            : +`${e?.['Start Time']?.match(/\d+/)?.[0]}` + 12
          : +`${e?.['Start Time']?.match(/\d+/)?.[0]}`,
      );
      Startdate.setMinutes(`${e?.['Start Time']?.match(/:(\d+)/)?.[0].replace(':', '')}`);
      Startdate.setSeconds("00");
      const Enddate = new Date();
      Enddate.setHours(
        e?.['End Time']?.includes('pm')
          ? +`${e?.['End Time']?.match(/\d+/)?.[0]}` == 12
            ? +`${e?.['End Time']?.match(/\d+/)?.[0]}`
            : +`${e?.['End Time']?.match(/\d+/)?.[0]}` + 12
          : +`${e?.['End Time']?.match(/\d+/)?.[0]}`,
      );
      Enddate.setMinutes(`${e?.['End Time']?.match(/:(\d+)/)?.[0].replace(':', '')}`);
      Enddate.setSeconds("00");
      return {
        id: e?.id,
        activity: e?.activity,
        start_time: Startdate,
        end_time: Enddate,
        rush: e?.rush,
      };
    }),
  };
};

export default EditUnbillableHoursDefaultValue;
