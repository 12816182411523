import { useEffect, useMemo, useRef, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import InputLabel from '../../components/InputLabel/InputLabel';
import RequestErrorCreateCompanyForm from './RequestErrorCreateCompanyForm';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import EditCompanyFormDefaultValue from './EditCompanyFormDefaultValue';
import { useDropzone } from 'react-dropzone';

export default function EditCompanyFormClient() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState();
  const [allClientsData, setAllClientsData] = useState([]);
  const [allAddClientsData, setAllAddClientsData] = useState([]);
  const [allParentClientsData, setAllParentClientsData] = useState([]);
  const [dataDefalt, setData] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: useMemo(() => EditCompanyFormDefaultValue(dataDefalt), [dataDefalt]),
    resolver: yupResolver(RequestErrorCreateCompanyForm()),
  });
  const navigate = useNavigate();

  const afterSubmit = () => {
    notifySuccess('Company Form successfully edited');
    setTimeout(() => {
      navigate('/Admin/CompanyFormsClients');
    }, 2000);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', acceptedFiles?.[0] ?? '');
    data.file = formData.get('file') || data?.file;
    companyFormsHttp
      ?.EditCompanyFormsClient(params?.id, data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/CompanyFormsClient');
  };

  useEffect(() => {
    companyFormsHttp.getSchoolsForCompanyForm().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Clients' });
      setAllClientsData(employesList);
    });
    companyFormsHttp.getAddSchoolsForCompanyForm().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.email || item?.[0]?.email,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Add Clients' });
      setAllAddClientsData(employesList);
    });
    companyFormsHttp.getGroupSchoolsForCompanyForm().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Parent Clients' });
      setAllParentClientsData(employesList);
    });
    params?.id &&
      companyFormsHttp.getCompanyFormsClient(params?.id).then(e => {
        setData(e?.data?.data);
        // setState(e?.data?.data?.file.uuid)
      });
  }, []);

  useEffect(() => {
    reset(EditCompanyFormDefaultValue(dataDefalt));
    if (dataDefalt?.file) {
      setState({ cdnUrl: dataDefalt?.file });
    }
  }, [dataDefalt]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Edit Company Form'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-end">
              <div className="mt-5">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Form Title'}
                  placeholder={'Details'}
                  register={register}
                  name={`title`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.title?.message}</p>}
              </div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Clients'}
                  selectData={allClientsData}
                  defalutValue={dataDefalt?.schools?.map(elem => {
                    return { value: elem?.id, label: elem?.name };
                  })}
                  selectClass="flex-col gap-2"
                  name={'schools'}
                  All="All Clients"
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.schools?.message}</p>}
              </div>
            </div>
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 space-y-5">
              <div className="w-full bg-white mt-5"></div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Additional Clients'}
                  selectData={allAddClientsData}
                  defalutValue={dataDefalt?.add_schools?.map(elem => {
                    return { value: elem?.id, label: elem?.email };
                  })}
                  selectClass="flex-col gap-2"
                  name={'add_schools'}
                  All="All Add Clients"
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.add_schools?.message}</p>}
              </div>
            </div>
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 space-y-5">
              <div className="w-full bg-white mt-5"></div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Parent Clients'}
                  selectData={allParentClientsData}
                  selectClass="flex-col gap-2"
                  All="All Parent Clients"
                  defalutValue={dataDefalt?.group_schools?.map(elem => {
                    return { value: elem?.id, label: elem?.name };
                  })}
                  name={'group_schools'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_schools?.message}</p>}
              </div>
            </div>
            <div className=" flex gap-4 mt-4 items-center">
            <div>
                {['png', 'jpeg', 'jpg', 'swg', 'webp']?.some(ext => state?.cdnUrl?.endsWith(ext)) && (
                  <img alt="swg" src={state?.cdnUrl} className="w-20 h-20" />
                )}
                {state?.cdnUrl.endsWith('pdf') && (
                  <button type="button">
                    <a href={state?.cdnUrl} download={state?.filename} target='_blank' className="w-full h-full" rel="noreferrer">
                      <div className='flex items-center'>
                        <img alt="swg" src={'/PDFIcon.png'} className="w-16 h-16" />
                        <p> {state?.cdnUrl.split('/').pop()}</p>
                      </div>
                    </a>
                  </button>
                )}
                {['csv','xlsx']?.some(ext => state?.cdnUrl?.endsWith(ext)) && (
                  <button type="button">
                    <a href={state?.cdnUrl} download={state?.filename} target='_blank' className="w-full h-full" rel="noreferrer">
                      <div className='flex items-center'>
                        <img alt="swg" src={'/ExcelPdf.svg'} className="w-16 h-16" />
                        <p> {state?.cdnUrl.split('/').pop()}</p>
                      </div>
                    </a>
                  </button>
                )}
                {['docx']?.some(ext => state?.cdnUrl?.endsWith(ext)) && (
                  <button type="button">
                    <a href={state?.cdnUrl} download={state?.filename} target='_blank' className="w-full h-full" rel="noreferrer">
                      <div className='flex items-center'>
                        <img alt="swg" src={'/WordPdf.svg'} className="w-16 h-16" />
                        <p> {state?.cdnUrl.split('/').pop()}</p>
                      </div>
                    </a>
                  </button>
                )}
              </div>
              <section className="container mt-5">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input
                    {...getInputProps()}
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    style={{ display:'block'}}
                  />
                </div>
              </section>
            </div>
          </div>
          <div className="mt-40 z-50">
            <CancleAdd disabled={!state || isLoading} buttonText={`Save`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
