import { Fragment } from 'react';
import CasesInnerFields from './CasesInnerFields/CasesInnerFields';

export default function CasesField({
  CasesFields,
  register,
  errors,
  CasesAppend,
  CasesRemove,
  setValue,
  clientsDataCurrent,
  dataDefalt,
  getValues
}) {
  const onClickAddNewCase = () => {
    CasesAppend({});
  };

  

  return (
    <div className="mt-5">
      {CasesFields.map((elem, index) => (
        <Fragment key={elem?.id}>
          <CasesInnerFields
            register={register}
            errors={errors}
            setValue={setValue}
            clientsDataCurrent={clientsDataCurrent}
            index={index}
            CasesRemove={CasesRemove}
            id={elem?.id}
            dataDefalt={dataDefalt}
            getValues={getValues}
          />
        </Fragment>
      ))}
      <div className="grid sm:px-7 px-4 grid-cols-1 sm:gap-6 gap-2 mt-5">
        <section className="text-end">
          <button
            className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
            type="button"
            onClick={onClickAddNewCase}>
            Add New Service
          </button>
        </section>
      </div>
    </div>
  );
}
