import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import TitleBlockServiceLog from '../../components/TitleBlockServiceLog/TitleBlockServiceLog';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import TableClients from '../../components/DataTable/TableClients';
import clientServiceLogHttp from '../../http/clientServiceLogHttp/clientServiceLogHttp';
import InvoiceModal from '../../components/Modal/InvoiceModal';
import TitleBlockCustomLogs from '../../components/TitleBlockCustomLogs/TitleBlockCustomLogs';
import AdminHttp from '../../http/adminProfile/adminProfile';
import Pagination from '../../components/Pagination/Pagination';
import { columnsServiceLogAdmin, columnsServiceLogAdminCustom } from './helpers/ServiceLogClientUtils';
import InfoListModal from '../../components/Modal/InfoListModal';

export default function ServiceLogClient() {
  const [data, setData] = useState([]);
  const [dataCustom, setDataCustom] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [dataPageCustom, setDataPageCustom] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [dateId, setDateId] = useState(null);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [AllClientData, setAllClientData] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [fileUrlCustom, setFileUrlCustom] = useState(null);
  const [activeTab, setActiveTap] = useState(1);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDesc, setIsDesc] = useState('desc');
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();
  const [title, setTitle] = useState();

  const changeTab = boolean => {
    setActiveTap(boolean);
    setDateRange(null);
    setDateId(null);
    setDataEmployeeCurrent('');
    setDataClientCurrent('');
    setIsDesc('desc');
  };

  const onClickEmployeeList = (list, title_) => {
    if (list?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
      setTitle(title_);
    }
  };

  const fetchDataDownload = async (
    id,
    employeId,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    partner_id,
    client_id,
  ) => {
    try {
      const response = await clientServiceLogHttp.serviceLogWeekAdminPDFLinks(id, employeId, partner_id, client_id);
      const fileName = `${employee_name}_week of ${week_of}_${client_name_from_admin}`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // setFileUrl({ url, filename: "ServiceLog", client_name });
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Set the filename dynamically
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  const fetchDataReglar = async (
    id,
    employeId,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    partner_id,
    client_id,
  ) => {
    try {
      const response = await clientServiceLogHttp.serviceLogWeekAdminPDFLinks(id, employeId, partner_id, client_id);
      const fileName = `${employee_name}_week of ${week_of}_${client_name_from_admin}`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrlCustom({ url, filename: fileName, id: id });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  const onClickDownload = (
    id,
    employeId,
    status,
    partner_id,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    client_id,
  ) => {
    fetchDataDownload(
      id,
      employeId,
      client_name,
      client_name_from_admin,
      employee_name,
      week_of,
      partner_id,
      client_id,
    );
  };
  const onClickViewRegular = (
    id,
    employeId,
    status,
    partner_id,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    client_id,
  ) => {
    setIsOpenView(true);
    fetchDataReglar(id, employeId, client_name, client_name_from_admin, employee_name, week_of, partner_id, client_id);
  };

  const fetchDataDownloadCustom = async (
    id,
    employeId,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    partner_id,
    client_id,
  ) => {
    try {
      const response = await clientServiceLogHttp.customLogWeekAdminPDFLinks(id);
      const fileName = `${employee_name}_date of ${week_of}_${client_name_from_admin}`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // setFileUrl({ url, filename: "ServiceLog", client_name });
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Set the filename dynamically
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  const fetchDataCustom = async (
    id,
    employeId,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    partner_id,
    client_id,
  ) => {
    try {
      const response = await clientServiceLogHttp.customLogWeekAdminPDFLinks(id);
      const fileName = `${employee_name}_date of ${week_of}_${client_name_from_admin}`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrlCustom({ url, filename: fileName, id: id });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  const onClickDownloadCustom = (
    id,
    employeId,
    status,
    partner_id,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    client_id,
  ) => {
    // clientServiceLogHttp.serviceLogWeekAdminLinks(id, employeId, partner_id).then((e) => setViewData(e?.data?.data))
    fetchDataDownloadCustom(
      id,
      employeId,
      client_name,
      client_name_from_admin,
      employee_name,
      week_of,
      partner_id,
      client_id,
    );
  };
  const onClickViewCustom = (
    id,
    employeId,
    status,
    partner_id,
    client_name,
    client_name_from_admin,
    employee_name,
    week_of,
    client_id,
  ) => {
    setIsOpenView(true);
    fetchDataCustom(id, employeId, client_name, client_name_from_admin, employee_name, week_of, partner_id, client_id);
  };

  const onPaginationClick = id => {
    // setPageId(id)
    activeTab
      ? clientServiceLogHttp
          .serviceLogDataLinks(dateRange, dateId, dataEmployeeCurrent, dataClientCurrent, isDesc, id)
          .then(e => {
            setDataPage(e?.data);
            const flatArray = [].concat(...e?.data?.data);
            setData(flatArray);
          })
      : clientServiceLogHttp
          .customServiceLogDataLinks(dateRange, dateId, dataEmployeeCurrent, dataClientCurrent, isDesc, id)
          .then(e => {
            setDataPageCustom(e?.data);
            const flatArray = [].concat(...e?.data?.data);
            setDataCustom(flatArray);
          });
  };

  useEffect(() => {
    EmployeesHttp.employeesAllByClientLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.full_name || item?.[0]?.full_name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    }).finally(() => {
      setLoading(false);
    });
    clientServiceLogHttp.getClientsForClient().then(e => {
      const uniqueIdsSet = new Set();
      const clientList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }

          return result;
        }, [])
      clientList.unshift({ id: '', name: 'All Clients' });
      clientList?.length ? setAllClientData(clientList) : setAllClientData([{ id: '', name: 'All Clients' }]);
    }).finally(() => {
      setLoading(false);
    });
    activeTab
      ? clientServiceLogHttp
          .serviceLogDataLinks(dateRange, dateId, dataEmployeeCurrent, dataClientCurrent, isDesc)
          .then(e => {
            setDataPage(e?.data);
            const flatArray = [].concat(...e?.data?.data);
            setData(flatArray);
          }).finally(() => {
            setLoading(false);
          })
      : clientServiceLogHttp
          .customServiceLogDataLinks(dateRange, dateId, dataEmployeeCurrent, dataClientCurrent, isDesc)
          .then(e => {
            setDataPageCustom(e?.data);
            const flatArray = [].concat(...e?.data?.data);
            setDataCustom(flatArray);
          }).finally(() => {
            setLoading(false);
          });
  }, [dateRange, dateId, dataEmployeeCurrent, activeTab, dataClientCurrent, isDesc]);

  useEffect(() => {
    AdminHttp.clientLinks().then(e => {
      setType(e?.data?.data?.type)
    });
  }, []);

  return (
    <>
      {type && (
        <div className={`sm:p-8 p-4 w-full overflow-hidden`}>
            <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={title ?? 'For which employees is this Monthly log'}
      />
          <InvoiceModal open={isOpenView} setOpen={setIsOpenView} fileUrl={fileUrlCustom} sendable={false} />
          {activeTab ? (
            <div className={`${loading && `animate-pulse`}`}>
              <TitleBlockServiceLog
                dontShow={true}
                setDateRange={setDateRange}
                setDateId={setDateId}
                AllEmployeesData={AllEmployeesData}
                unbillableHoursAdmin
                setDataEmployeeCurrent={setDataEmployeeCurrent}
                textItem="Weekly Service Logs"
                className="sm:text-base text-xs	 font-semibold"
                AllClientsData={type === 'Parent' || type === 'Add School' ? AllClientData : []}
                setDataClientCurrent={setDataClientCurrent}
                Parent={type === 'Parent' || type === 'Add School'}
              />
            </div>
          ) : (
            <div className={`${loading && `animate-pulse`}`}>
              <TitleBlockCustomLogs
                dontShow={true}
                client={true}
                setDateRange={setDateRange}
                setDateId={setDateId}
                AllEmployeesData={AllEmployeesData}
                setDataEmployeeCurrent={setDataEmployeeCurrent}
                dataEmployeeCurrent={dataEmployeeCurrent}
                textItem="Monthly Service Logs"
                className="sm:text-base text-xs	 font-semibold"
                AllClientsData={type === 'Parent' || type === 'Add School' ? AllClientData : []}
                setDataClientCurrent={setDataClientCurrent}
                Parent={type === 'Parent' || type === 'Add School'}
              />
            </div>
          )}

          <div className="">
            <div className="px-4 py-1 mt-4 bg-white rounded-xl">
              <div className="w-full flex flex-col mt-2">
                <div className="mt-2 w-full">
                  <div className="flex sm:gap-4 gap-2">
                    <div onClick={() => changeTab(true)} className={'w-1/2 cursor-pointer'}>
                      <p
                        className={
                          activeTab
                            ? 'text-lg md:text-xl font-semibold pl-4 flex gap-7'
                            : 'text-[#686973] text-lg md:text-xl  font-semibold pl-4 flex gap-7'
                        }>
                        {'Weekly Service Logs'}
                      </p>
                      <div
                        className={
                          activeTab
                            ? 'bg-[#70ACFB] rounded  w-full h-[6px] mt-3'
                            : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'
                        }></div>
                    </div>
                    <div onClick={() => changeTab(false)} className={'w-1/2 cursor-pointer'}>
                      <p
                        className={
                          !activeTab
                            ? 'text-lg md:text-xl font-semibold pl-4 flex gap-7'
                            : 'text-[#686973] text-lg md:text-xl font-semibold pl-4 flex gap-7'
                        }>
                        {'Monthly Service Logs'}
                      </p>
                      <div
                        className={
                          !activeTab
                            ? 'bg-[#70ACFB] rounded  w-full h-[6px] mt-3'
                            : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'
                        }></div>
                    </div>
                  </div>
                </div>
              </div>
              {activeTab ? (
                <>
                  <div className="overflow-x-auto mt-8">
                    <div className={`inline-block min-w-full ${loading && `animate-pulse`}`}>
                      <TableEmployeesAdmin
                        tbody={data ?? []}
                        columns={columnsServiceLogAdmin(setIsDesc, isDesc, onClickDownload, onClickViewRegular)}
                        maxHeight="500px"
                      />
                    </div>
                  </div>
                  <div>
                    <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
                  </div>
                </>
              ) : (
                <>
                  <div className="overflow-x-auto mt-8">
                    <div className={`inline-block min-w-full ${loading && `animate-pulse`}`}>
                      <TableClients
                        tbody={dataCustom ?? []}
                        columns={columnsServiceLogAdminCustom(
                          setIsDesc,
                          isDesc,
                          onClickDownloadCustom,
                          onClickViewCustom,
                          onClickEmployeeList
                        )}
                        maxHeight="500px"
                      />
                    </div>
                  </div>
                  <div>
                    <Pagination onPaginationClick={onPaginationClick} paginationData={dataPageCustom.links} />
                  </div>
                </>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}
