import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const Protected = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  if (!token) {
    return <Navigate to={'/Admin/LoginPage'} replace />;
  } else if (role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  } else if (role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  } else if (role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }
  return <Outlet />;
};
export const ProtectedNotLogin = () => {
  const x = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  if (x && role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  }
  if (x && role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  }
  if (x && role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  }
  if (x && role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }
  return <Outlet />;
};

export const Admin = () => {
  const location = useLocation().pathname.startsWith('/Admin');
  if (!location) {
    return <Navigate to={'/Employee/LoginPage'} replace />;
  }
  return <Outlet />;
};

// export const Client = () => {
//     const x = 0

//     if (!x) {
//         return <Navigate to={'Dashboard'} replace />
//     }
//     return <Outlet />
// }

export const Client = () => {
  const location = useLocation().pathname.startsWith('/Client');

  if (!location) {
    return <Navigate to={'/Admin/LoginPage'} replace />;
  }
  return <Outlet />;
};
export const SuperAdmin = () => {
  const location = useLocation().pathname.startsWith('/SuperAdmin');

  if (!location) {
    return <Navigate to={'/SuperAdmin/LoginPage'} replace />;
  }
  return <Outlet />;
};
// -----------------------------------------
export const Employee = () => {
  const location = useLocation().pathname.startsWith('/Employee');

  if (!location) {
    return <Navigate to={'/Admin/LoginPage'} replace />;
  }
  return <Outlet />;
};
// --------------------Employee

export const ProtectedEmployee = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  if (!token) {
    return <Navigate to={'/Employee/LoginPage'} replace />;
  } else if (role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  } else if (role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  } else if (role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }
  return <Outlet />;
};
export const ProtectedClient = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  if (!token) {
    return <Navigate to={'/Client/LoginPage'} replace />;
  } else if (role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  } else if (role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  } else if (role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }
  return <Outlet />;
};
export const ProtectedSuperAdmin = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  if (!token) {
    return <Navigate to={'/SuperAdmin/LoginPage'} replace />;
  } else if (token && role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  } else if (token && role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  } else if (token && role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  }
  return <Outlet />;
};

export const ProtectedEmployeeNotLogin = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  if (token && role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  }
  if (token && role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  }
  if (token && role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  }
  if (token && role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }

  return <Outlet />;
};
export const ProtectedClientNotLogin = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  if (token && role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  }
  if (token && role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  }
  if (token && role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  }
  if (token && role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }

  return <Outlet />;
};
export const ProtectedSuperAdminNotLogin = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  if (token && role === 'admin') {
    return <Navigate to={'/Admin/Dashboard'} replace />;
  }
  if (token && role === 'employee') {
    return <Navigate to={'/Employee/Dashboard'} replace />;
  }
  if (token && role === 'client') {
    return <Navigate to={'/Client/ServiceLog'} replace />;
  }
  if (token && role === 'super-admin') {
    return <Navigate to={'/SuperAdmin/Dashboard'} replace />;
  }
  return <Outlet />;
};
