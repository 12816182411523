

export const AddServiceDefaultValue = () => {
 
    return {
        positions: [{ name: "" }],
        // requestActivities: [{ name: "" }],
        service_types: [{ name: "1:1" },{ name: "1:1 and Classroom support" },{ name: "Embedded support" },{ name: "Classroom support" }],
        activities: [{ name: "" }],
        name: "",
        details: "",
        code: "",
        status: true,
        id: "",
    }
}

export default AddServiceDefaultValue