import classNames from "../../utils/utils";

export default function RadioGroupWrapper({ arr, register, className, editData }) {
  return (
    <div className="flex items-center justify-between">
      {arr.map((notificationMethod) => (
        <div key={notificationMethod.id} className="flex items-center ">
          <input
            className={classNames("h-4 w-4 border-gray-300 focus:ring-indigo-600", className)}
            value={notificationMethod?.id}
            {...register(`${notificationMethod.name}`)}
            type="radio"
          />
          <label htmlFor={`${notificationMethod.id}`} className={classNames("ml-3 block leading-6 text-gray-900", className)}>
            {notificationMethod.title} 
          </label>
        </div>
      ))}
    </div>
  )
}