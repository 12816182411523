import { useFieldArray } from 'react-hook-form';

export default function CheckboxesFields({ index, control, register }) {
  const { fields: Options } = useFieldArray({
    control,
    name: `formFields.${index}.options`,
  });

  return (
    <>
      {Options?.map(({ name }, indexOpt) => (
        <div className="relative flex items-start" key={name + indexOpt}>
          <div className="flex h-6 items-center">
            <input
              id={`formFields.${index}.options.${indexOpt}.${name}`}
              aria-describedby="comments-description"
              name={`formFields.${index}.options.${indexOpt}.${name}`}
              {...register(`formFields.${index}.options.${indexOpt}.${name}`)}
              type="checkbox"
              className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={`formFields.${index}.options.${indexOpt}.${name}`} className="font-medium text-gray-900">
              {name}
            </label>
          </div>
        </div>
      ))}
    </>
  );
}
