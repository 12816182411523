import * as yup from 'yup';

export const RequestErrorAssignRequestToEmployee = isEmpty => {
  return yup.object().shape({
    employee_ids: isEmpty ? yup.array().min(1).required('This field is required') : yup.array(),
    deadline: yup.string().required('This field is required'),
  });
};

export default RequestErrorAssignRequestToEmployee;
