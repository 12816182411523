import { useEffect, useState } from 'react';
import casesHttp from '../../../../http/casesHttp/casesHttp';
import Select from '../../../../components/Select/Select';
import MultiSelect from '../../../../components/MultiSelect/MultiSelect';

export default function CasesInnerFields({ register, errors, setValue, clientsDataCurrent, index, CasesRemove, id, }) {
  const [servicesData, setServiesData] = useState([]);
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);

  const [clientsDataServiceCurrent, setClientsDataServiceCurrent] = useState();


  const onChangeServiceData = (e, index) => {
    setClientsDataServiceCurrent(e.target.value); // Update service_id
    setValue(`cases.${index}.service_id`, e.target.value); // Set the form value for service_id
    setValue(`cases.${index}.employees`, []); // Reset MultiSelect values
    setEmployeesData([]); // Clear employees data
    setValue(`cases.${index}.service_type_id`, ''); // Reset service type
  };

  useEffect(() => {
    setClientsDataServiceCurrent('');
    clientsDataCurrent &&
      casesHttp.getServiceForCases(clientsDataCurrent).then(e => {
        setServiesData(e?.data?.data);
      });
  }, [clientsDataCurrent]);

  useEffect(() => {
    if (clientsDataCurrent && clientsDataServiceCurrent) {
      setValue(`cases.${index}.employees`, []); // Clear selected employees
      setEmployeesData([]); // Reset employees data

      // Fetch service types
      casesHttp.getServiceTypesForCases(clientsDataServiceCurrent).then(e => {
        setServiceTypeData(e?.data?.data);
      });

      // Fetch employees
      casesHttp.getEmployeesForCases(clientsDataServiceCurrent, clientsDataCurrent).then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            value: item?.id || item?.[0]?.employee_id,
            label: item?.name || item?.[0]?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.value)) {
              uniqueIdsSet.add(item.value);
              result.push(item);
            }
            return result;
          }, []);
        employesList.unshift({ value: 'All', label: 'All Employees' });
        setEmployeesData(employesList);
      });
    }
  }, [clientsDataCurrent, clientsDataServiceCurrent]);



  useEffect(() => {
    setValue(`cases.${index}.service_id`, '');
  }, [servicesData]);

  useEffect(() => {
    setValue(`cases.${index}.service_type_id`, '');
  }, [serviceTypeData]);

  return (
    <div key={id} className="mt-5">
      <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
        <div className="w-full bg-white">
          <Select
            register={register}
            title={'Service'}
            selectData={servicesData}
            selectClass="flex-col gap-2"
            onChange={onChangeServiceData}
            name={`cases.${index}.service_id`}
            className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.cases?.[index]?.service_id?.message}</p>}
        </div>
        <div className="w-full bg-white">
          <Select
            register={register}
            title={'Service Type'}
            selectData={serviceTypeData}
            selectClass="flex-col gap-2"
            name={`cases.${index}.service_type_id`}
            className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.cases?.[index]?.service_type_id?.message}</p>}
        </div>
      </div>
      <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2 mt-5">
        <div className="w-full bg-white">
          <MultiSelect
            title={'To Employee'}
            selectData={employeesData}
            selectClass="flex-col gap-2"
            name={`cases.${index}.employees`}
            className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
            setValue={setValue}
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.cases?.[index]?.employees?.message}</p>}
        </div>
      </div>
      {index !== 0 && (
        <div className="grid sm:px-7 px-4 mt-5 grid-cols-1 sm:gap-6 gap-2">
          <section className="text-end ">
            <button
              onClick={() => {
                CasesRemove(index);
              }}
              className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
              type="button">
              Remove Service
            </button>
          </section>
        </div>
      )}
    </div>
  );
}
