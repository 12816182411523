import { useEffect, useState } from "react";
import Select from "../../components/Select/Select";
import AdminServiceLogHttp from "../../http/adminServiceLogHttp/adminServiceLogHttp";


export default function ServiceTypeAndCaseAdmin({ index,register,errors,setValue,clientsDataCurrent,clientsDataServiceCurrent,employe_id}) {

  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [newCaseData, setNewCaseData] = useState([]);

  const [serviceTypeCurrent, setServiceTypeCurrent] = useState();

  const onChangeServiceTypeData = (e,index) => {
    setServiceTypeCurrent(e.target.value);
    setValue(`serviceLogTimeActivities.${index}.service_type_id`, e.target.value);
    setValue(`serviceLogTimeActivities.${index}.new_case_id`, '');
  };

  useEffect(() => {
    clientsDataCurrent &&
    clientsDataServiceCurrent &&
    AdminServiceLogHttp.getServiceTypesLinks(clientsDataServiceCurrent,employe_id,clientsDataCurrent).then(e =>
      setServiceTypeData(e?.data?.data),
    );
  }, [clientsDataServiceCurrent, clientsDataCurrent]);

  useEffect(() => {
    clientsDataCurrent &&
      clientsDataServiceCurrent &&
      serviceTypeCurrent &&
      AdminServiceLogHttp
        .getNewCasesLinks(clientsDataServiceCurrent, clientsDataCurrent, serviceTypeCurrent,employe_id)
        .then(e => {
          setNewCaseData(e?.data?.data);
        });
  }, [clientsDataServiceCurrent, clientsDataCurrent, serviceTypeCurrent]);

    useEffect(() => {
      setValue(`serviceLogTimeActivities.${index}.service_type_id`, '');
  }, [serviceTypeData]);

  useEffect(() => {
      setValue(`serviceLogTimeActivities.${index}.new_case_id`, '');
  }, [newCaseData]);


  return (
    <div className="grid md:grid-cols-2 gap-4">
    {serviceTypeData && (
      <div className="w-full bg-white mt-2">
        <Select
          register={register}
          title={'Service Type'}
          selectData={serviceTypeData}
          onChange={onChangeServiceTypeData}
          selectClass="flex-col gap-2"
          name={`serviceLogTimeActivities.${index}.service_type_id`}
          className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
          classNameTitle="text-[#60657B] opacity-50 text-xs"
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">
            {errors?.serviceLogTimeActivities?.[index]?.service_type_id?.message}
          </p>
        )}
      </div>
    )}
    {newCaseData && (
      <div className="w-full bg-white mt-2">
        <Select
          register={register}
          title={'Case'}
          selectData={newCaseData}
          selectClass="flex-col gap-2"
          name={`serviceLogTimeActivities.${index}.new_case_id`}
          className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
          classNameTitle="text-[#60657B] opacity-50 text-xs"
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">
            {errors?.serviceLogTimeActivities?.[index]?.new_case_id?.message}
          </p>
        )}
      </div>
    )}
  </div>
  );
}
