import { $authHost,} from "..";

export default class requestHttp {

    static getRequestForms(id) {
        return $authHost.get(`/admin/forms?page=${id ? id : ""}`)
    };
    static getRequests(id) {
        return $authHost.get(`/admin/submitted_forms?page=${id ? id : ""}`)
    };
    static getRequestForm(id) {
        return $authHost.get(`/admin/forms/${id}`)
    };
    static createRequestForm(data) {
        return $authHost.post(`/admin/forms`,data)
    };
    static editRequestForm(id,data) {
        return $authHost.post(`/admin/forms/${id}`,data)
    };
    static deleteRequestForm(id) {
        return $authHost.post(`/admin/delete_form/${id}`,)
    };
    static deleteRequest(id) {
        return $authHost.post(`/admin/delete_submitted_form/${id}`,)
    };
    static assignRequestFormToClients(id,data) {
        return $authHost.post(`/admin/assign_school_form/${id}`,data)
    };
    static assignRequestFormToEmployees(id,data) {
        return $authHost.post(`/admin/assign_to_employee/${id}`,data)
    };
    static getSubmittedFormAdmin(id) {
        return $authHost.get(`/admin/submitted_form/${id}`)
    };
    static editSubmittedFormAdmin(id,data) {
        return $authHost.post(`/admin/update/submitted_form/${id}`,data)
    };
    static getSubmittedEmployeesAdmin(id) {
        return $authHost.get(`/admin/submitted_form_with_employee/${id}`,)
    };
    // -----------------------------------------------
    static getRequestFormsClient(id) {
        return $authHost.get(`/client/getForms?page=${id ? id : ""}`)
    };
    static getMyRequestsClient(id) {
        return $authHost.get(`/client/getSubmittedForms?page=${id ? id : ""}`)
    };
    static getSubmittedFormClient(id) {
        return $authHost.get(`/client/submitted_form/${id}`)
    };
    static getFormForFillClient(id) {
        return $authHost.get(`/client/getForms/${id}`)
    };
    static fillFormForClient(id,data) {
        return $authHost.post(`/client/submit/${id}`,data)
    };
    static editFormForClient(id,data) {
        return $authHost.post(`/client/submit_form/${id}`,data)
    };
    static deleteRequestForClient(id) {
        return $authHost.post(`/client/submit_form_delete/${id}`)
    };


} 
