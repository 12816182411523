// function formatDate(dateString) {
//   const formattedDate = dateString?.split("-")?.reverse()?.join("/");
//   return formattedDate;
// }

function formatDate(dateString) {
  if (dateString) {
    const [year, month, day] = dateString?.split('-');

    return `${month}/${day}/${year}`;
  } else return '';
}

export const columnsRequest = (onClickEmployeeList, onClickEdit, onClickRemove, onClickAssignEmployees) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.id}</p>,
  },
  {
    accessorKey: 'name',
    header: 'name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: 'form_type',
    header: 'Form Type', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
  },
  {
    accessorKey: 'employees',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => (
      <p className="whitespace-nowrap cursor-pointer" onClick={() => onClickEmployeeList(row?.original?.['employees']?.map((elem)=>elem?.name))}>
        {row?.original?.['employees']?.map(
          ({ name }, index, array) => `${name + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'client',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school}</p>,
  },
  {
    accessorKey: 'Date',
    header: 'Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{formatDate(row?.original?.created_at)}</p>,
  },
  {
    accessorKey: 'deadline',
    header: 'Deadline', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{formatDate(row?.original?.deadline)}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 60,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.status}</p>,
  },

  {
    accessorKey: 'action',
    header: 'Action',
    size: 230, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        {onClickEdit && (
          <button
            onClick={() => onClickEdit(row?.original?.id)}
            className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            type="button">
            Edit
          </button>
        )}
        {onClickAssignEmployees && row?.original?.name && (
          <button
            onClick={() =>
              onClickAssignEmployees(row?.original?.id, row?.original?.title, row?.original?.['employees'])
            }
            className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            type="button">
            {row?.original?.['employees']?.length > 0 ? 'Edit Assign Employees' : 'Assign Employees'}
          </button>
        )}
        {onClickRemove && (
          <img
            src="/RemoveSVG.svg"
            alt="Remove"
            onClick={() => onClickRemove(row?.original?.['id'])}
            className="cursor-pointer"
          />
        )}
      </div>
    ),
  },
];
