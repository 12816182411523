import { useFieldArray } from "react-hook-form";
import InputLabel from "../../components/InputLabel/InputLabel";

export default function StudentsFieldArray({ register, control,errors, }) {

    const {
        fields: StudentsFields,
        append: StudentsAppend,
        remove: StudentsRemove,
    } = useFieldArray({
        control,
        name: `students`,
    });

    const onClickAddStudent = () => {
        StudentsAppend({});
    }

    return (
        <div>
            {StudentsFields.map((item, studentsindex) => {
                return (
                    <div key={item.id} className={`${studentsindex !==0 ? "mt-2" : ""}`}>
                        <div className="flex items-center w-full">
                            <InputLabel labelTitle={"Student Name"}
                                inputId={`students.${studentsindex}.name`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Student Name"}
                                classNameInput="w-full"
                                name={`students.${studentsindex}.name`}
                                register={register}
                                classNameLabel={"text-[#60657B] w-full opacity-50 text-xs font-normal"}
                            />
                            {studentsindex >= 1 && <button type="button" className="w-8 h-[42px] mt-6 rounded border border-[#E4E6F2] text-[#60657B]" onClick={() => StudentsRemove(studentsindex)}>
                                X
                            </button>}
                        </div>
                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.students?.[studentsindex]?.name?.message}</p>}
                        {studentsindex === 0 && 
                        <section className="mt-1">
                            <button
                                className="text-[#2B2D38] opacity-50 text-base"
                                type="button"
                                onClick={onClickAddStudent}
                            >
                                + Add Student
                            </button>
                        </section>}
                    </div>
                );
            })}
        </div>
    )
}