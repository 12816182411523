import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsCases } from './helpers/CasesUtils';
import casesHttp from '../../http/casesHttp/casesHttp';
import InfoListModal from '../../components/Modal/InfoListModal'

export default function Cases() {
  const navigate = useNavigate();
  const [casesList, setCasesList] = useState([]);
  const [casesListPage, setCasesListDataPage] = useState();
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();
  const [title, setTitle] = useState();

  const onPaginationClick = id => {
    casesHttp.getCasesList(id).then(e => {
      setCasesListDataPage(e?.data);
      setCasesList(e?.data?.data);
    });
  };

  const onClickEmployeeList = (list,title_) => {
    if (list?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
      setTitle(title_)
    }
  };

  const onClickRemove = (id, haveLogs) => {
    !haveLogs && setIsOpen(true);
    !haveLogs && setCompanyFormId(id);
  };

  const onClickYes = id => {
    casesHttp.deleteCase(id).then(e => e.status === 200 && setNewLoad(!newLoad));
    setIsOpen(false);
  };

  const onClickEdit = (id) => {
     navigate(`/Admin/EditCase/${id}`);
  };

  useEffect(() => {
    casesHttp.getCasesList('').then(e => {
      setCasesListDataPage(e?.data);
      setCasesList(e?.data?.data);
    });
  }, [newLoad]);

  return (
    <div className="w-full sm:p-8 p-2">
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={companyFormId}
        text="Are you sure you want to delete this Assignment/Case ?"
      />
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={title??'For which employees is this Assignment/Case'}
      />
      <TitleBlok src={'/CompanyFormsIcon.svg'} textItem={'Assignment/Cases'} className="text-base font-bold">
        <div className="flex sm:gap-4 gap-2">
          <Button
            onClick={() => navigate('/Admin/CreateCase')}
            buttonText={'Create New Case'}
            className={'text-[#fff] sm:text-sm text-xs'}
          />
        </div>
      </TitleBlok>
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={casesList ?? []}
              columns={columnsCases(onClickEdit, onClickRemove, onClickEmployeeList)}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={casesListPage?.links} />
      </div>
    </div>
  );
}
