import { useNavigate } from "react-router-dom";
import classNames, { onlyNumbers } from "../../utils/utils";
import  debounce  from "debounce";
import DatePicker from "react-multi-date-picker";

export default function TitleBlockCustomLogs({
    textItem, children, className, classNameTitleBlock, dontShow, setDateId, setDataEmployeeCurrent, AllEmployeesData, client, setDateRange, setCurrentPage, AllClientsData, setDataClientCurrent, Parent,setDataPublishCurrent,setIsOpenCreateModal,setIsOpenSendModalCustom,setIsOpenPublishModalCustom
}) {

    const debouncedOnChange = debounce((e) => {
        setDateId(e?.target?.value)
        setCurrentPage && setCurrentPage(1)
    }, 500);

    const navigate = useNavigate()

    const handleDateChange = (value) => {
        const date = {
            "startDate": value?.[0]?.format("MM/DD/YYYY"),
            "endDate": value?.[1]?.format("MM/DD/YYYY")
        }
        setDateRange(date);
        setCurrentPage && setCurrentPage(1)
    };

    const onChangeEmployee = (e) => {
        setDataEmployeeCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    }
    const onChangeClients = (e) => {
        setDataClientCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    }

    const onChangePublish = (e) => {
        setDataPublishCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    }

    const PublishedData = [{name:"All ",id:""},{name:"Published",id:1},{name:"Unpublished",id:0}]

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={AllEmployeesData?.length ? "flex lg:flex-row flex-col items-center gap-2  w-full pr-2" : "flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2"}>
                <img src="/TitleBlokIcon.svg" alt="img" />
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                {!dontShow && <div className="w-full">
                    <button onClick={() => setIsOpenPublishModalCustom(true)} className="text-[#4885ED] border px-4 border-[#4885ED] rounded py-1.5 sm:text-sm text-xs hover:text-indigo-900 w-full">
                        Publish Custom logs
                    </button>
                </div>}
                {!dontShow && <div className="w-full">
                    <button onClick={() => setIsOpenSendModalCustom(true)} className="text-[#4885ED] border px-4 border-[#4885ED] rounded py-1.5 sm:text-sm text-xs hover:text-indigo-900 w-full">
                        Send Custom logs
                    </button>
                </div>}
               
                {!dontShow && <div className="w-full">
                    <button onClick={() => setIsOpenCreateModal(true)} className="text-[#4885ED] border px-4 border-[#4885ED] rounded py-1.5 sm:text-sm text-xs hover:text-indigo-900 w-full">
                        Create Custom Log
                    </button>
                </div>}
                {client && <div className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[30px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
                    <DatePicker
                        range
                        // className="h-40px"
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY - MM/DD/YYYY"
                        // value={dateRange}
                        onChange={handleDateChange}
                    />
                </div>}

                {!dontShow && <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <img src="/search.svg" alt="img" />
                    </div>
                    <input onKeyPress={onlyNumbers} placeholder="search by id" onChange={debouncedOnChange} className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]" />
                </div>}
                {!dontShow && PublishedData?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangePublish}
                        >
                            {PublishedData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
                {AllEmployeesData?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangeEmployee}
                        >
                            {AllEmployeesData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
                {Parent && AllClientsData?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangeClients}
                        >
                            {AllClientsData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
            </div>
            {children}
        </div >
    )
}