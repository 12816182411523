import classNames from '../../../utils/utils';

export const columnsServiceLogEmployee = (navigate, onClickView) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.id}</p>,
  },
  {
    accessorKey: 'week of',
    header: 'Week Of', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['week of']}</p>,
  },
  {
    accessorKey: 'services',
    header: 'Services', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) =>
      row?.original?.services?.map((y, i) => (
        <li
          key={i}
          className="list-disc whitespace-nowrap text-ellipsis whitespace-nowrap min-w-[60px] overflow-hidden sm:text-sm text-xs font-medium text-gray-900">
          {y?.text || y?.name}
        </li>
      )),
    // <p className="whitespace-nowrap">{row?.original?.["school"]?.[0] ?? ""}</p>
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['hours']}</p>,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'Approved' : row?.original?.status == '2' ? 'Completed' : 'Not Completed'}
      </p>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 90, //decrease the width of this column
    // maxSize: 100,
    Cell: ({ row }) =>
      row?.original?.['status'] == '0' ? (
        <div className="flex gap-2">
          <button
            onClick={() => navigate(`Calendar/${row?.original.id}`)}
            className="text-[#4885ED] border px-4 border-[#4885ED] block rounded py-1 sm:text-sm text-xs hover:text-indigo-900 ">
            {<img className="max-w-[60px] h-[20px] block" src="/CalendarIcon.svg" alt="img" />}
          </button>
        </div>
      ) : (
        <div className="flex gap-2">
          <button
            onClick={() => navigate(`Calendar/${row?.original.id}`)}
            className="text-[#4885ED] border px-4 border-[#4885ED] block rounded py-1 sm:text-sm text-xs hover:text-indigo-900 ">
            {<img className="max-w-[60px] h-[20px] block" src="/CalendarIcon.svg" alt="img" />}
          </button>
          <button
            onClick={() => onClickView(row?.original?.id)}
            className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
            View
          </button>
        </div>
      ),
  },
];

export const columnsServiceLogViewDataEmployee = onClickCommentShow => [
  {
    accessorKey: 'date',
    header: 'Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => (
      // row?.original?.map((elem) =>
      <div>
        <p className="whitespace-nowrap">{row?.original?.day}</p>
        <p className="whitespace-nowrap">{row?.original?.name}</p>
      </div>
      // )
    ),
  },
  {
    accessorKey: 'start time',
    header: 'Start Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        elem => (
          // <div>
          <p className="whitespace-nowrap">{elem?.['Start Time']}</p>
        ),

      ),
  },
  {
    accessorKey: 'end time',
    header: 'End Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        elem => (
          // <div>
          <p className="whitespace-nowrap">{elem?.['End Time']}</p>
        ),

      ),
  },
  {
    accessorKey: 'Total Time',
    header: 'Total Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        elem => (
          // <div>
          <p className="whitespace-nowrap">{elem?.['Total Time']}</p>
        ),
      ),
  },
  {
    accessorKey: 'client',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        elem => (
          // <div>
          <p className="whitespace-nowrap">{elem?.['client']}</p>
        ),
      ),
  },
  {
    accessorKey: 'Positions',
    header: 'Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        elem => (
          // <div>
          <p className="whitespace-nowrap">{elem?.['Positions']}</p>
        ),
      ),
  },
  {
    accessorKey: 'activity',
    header: 'Description', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(elem => (
        <li
          className={'whitespace-nowrap overflow-hidden cursor-pointer'}
          onClick={() =>
            onClickCommentShow({
              id: elem?.['id'],
              day: row?.original?.day,
              start: elem?.['Start Time'],
              end: elem?.['End Time'],
              comment: `${elem?.['activity']?.[0]?.label} ${elem?.rush == 0 ? '' : ' (Rush)'}`,
            })
          }>
          {elem?.['activity']?.[0]?.label}
          {elem?.rush == 0 ? '' : ' (Rush)'}
        </li>
      )),
  },
];
