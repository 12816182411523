import { useFieldArray, useForm } from 'react-hook-form';
import Select from '../../components/Select/Select';
import Title from '../../components/Title/Title';
import { useEffect, useMemo, useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import DatePickerTime from '../../components/DatePickerTime/DatePickerTime';
import EditServiceLogAdminDefaultValue from './EditServiceLogAdminDefaultValue';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import serviceLogHttp from '../../http/serviceLogHttp/serviceLogHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import TimerDifference from '../../components/TimerDifference/TimerDifference';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorEditServiceLogAdmin from './RequestErrorEditServiceLogAdmin';
import TotalTime from '../AddServiceLog/TotalTime';
import CreateableSelect from '../../components/CreateableSelect/CreateableSelect';
import MapInput from '../../components/MapInput/MapInput';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import AdminServiceLogHttp from '../../http/adminServiceLogHttp/adminServiceLogHttp';
import ServiceTypeAndCaseAdmin from './ServiceTypeAndCaseAdmin';

export default function EditServiceLogAdmin() {
  const { state } = useLocation();
  const errorTime = useSelector(state => state?.error?.error);
  const [errorss, setErrorss] = useState(false);
  const [diableAdd, setDiableAdd] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState(null);
  const [data1, setData1] = useState(null);
  const [clientsData, setClientsData] = useState();
  const [servicesData, setServiesData] = useState([]);
  const [clientsDataServiceCurrent, setClientsDataServiceCurrent] = useState();
  const [clientsDataCurrent, setClientsDataCurrent] = useState();
  const [positionsData, setPositionsData] = useState([]);
  const [mapInputActive, setMapInputActive] = useState(false);
  const [distance, setDistance] = useState();
  const [errorWhenAprrove, setErrorWhenAprrove] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRush, setIsRush] = useState(false);
  const [isOverTime, setOverTime] = useState(false);
  const isMileageReimbursement = data?.mileageReimbursement == 1 ? true : false;
  const [hasServiceType, setHasServiceType] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: useMemo(() => EditServiceLogAdminDefaultValue(data), [data]),
    resolver: yupResolver(useMemo(() => RequestErrorEditServiceLogAdmin(hasServiceType), [hasServiceType])),
  });

  const {
    fields: ServiceLogTimeActivitiesFields,
    append: ServiceLogTimeActivitiesAppend,
    remove: ServiceLogTimeActivitiesRemove,
  } = useFieldArray({
    control,
    name: 'serviceLogTimeActivities',
  });

  const onChangeClientsData = e => {
    setClientsDataCurrent(e.target.value);
    setValue('school_id', e.target.value);
    setClientsDataServiceCurrent('');
    setValue('service_id', '');
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.service_type_id`, '');
      setValue(`serviceLogTimeActivities.${index}.new_case_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
      setValue(`serviceLogTimeActivities.${index}.rush`, false);
    });
  };

  const onChangeServiceData = e => {
    setClientsDataServiceCurrent(e.target.value);
    setValue('service_id', e.target.value);
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.service_type_id`, '');
      setValue(`serviceLogTimeActivities.${index}.new_case_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
    });
  };


  const afterSubmit = () => {
    notifySuccess('Service Log successfully changed');
    setTimeout(() => {
      navigate(`/Admin/ServiceLog/Employee/${state?.id2}/Calendar/${state?.id1}`);
    }, 2000);
  };
  
  const afterDelete = () => {
    notifySuccess('Service Log successfully Deleted');
    setTimeout(() => {
      navigate(`/Admin/ServiceLog/Employee/${state?.id2}/Calendar/${state?.id1}`);
    }, 2000);
  };
  
  const tazaData = {
    latitude1: data2?.latitude1,
    longitude1: data2?.longitude1,
    latitude2: data1?.latitude2,
    longitude2: data1?.longitude2,
    from: data2?.from,
    to: data1?.to,
  };

  const onError = err => {
    (err || err?.response?.data?.message || err?.response?.data?.errors) && setIsLoading(false);
    err?.response?.status == '403'
      ? setErrorWhenAprrove('You can not change Approved Service Log')
      : setErrorWhenAprrove(err?.response?.data?.message || err?.response?.data?.errors);
  };
  
  const onSubmit = data => {
    setIsLoading(true);
    data.serviceLogTimeActivities = data.serviceLogTimeActivities.map(elem => ({
      id: elem?.id,
      position_id: elem?.position_id,
      new_case_id: elem?.new_case_id,
      service_type_id: elem?.service_type_id,
      rush: elem?.rush ? 1 : 0,
      activity_id: elem?.activiteee?.value !== elem?.activiteee?.label ? elem?.activiteee?.value : null,
      activity: elem?.activiteee?.value == elem?.activiteee?.label ? elem?.activiteee?.value : null,
      start_time:
        typeof elem?.start_time === 'string' && !elem?.start_time?.includes('GMT')
          ? elem?.start_time
          : elem?.start_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      end_time:
        typeof elem?.end_time === 'string'
          ? elem?.end_time
          : elem?.end_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
    }));
    data.mileReimbursement = distance?.distance != 0 && distance?.distance != undefined ? [distance] : [];
    AdminServiceLogHttp.serviceLogUpdateCurrentLinks(params?.id, data)
      .then(() => afterSubmit())
      .catch(onError);
  };



  const onClickAddNewTimeActivity = lastTime => {


    // const Startdate = new Date(lastTime);
    // Startdate.setMinutes(Startdate.getMinutes());
    // Startdate.setHours(Startdate.getHours());
    // Startdate.setSeconds('00');
    // const Enddate = new Date(lastTime);
    // Enddate.setMinutes(Enddate.getMinutes());
    // Enddate.setHours(Enddate.getHours()+1);
    // Enddate.setSeconds('00');

    const Startdate = new Date(lastTime);
    Startdate.setMinutes(Startdate.getMinutes());
    Startdate.setHours(Startdate.getHours());
    Startdate.setSeconds('00');
    const Enddate = new Date(lastTime);
    Enddate.setMinutes(Startdate.getMinutes() == '45' ? '00' : Enddate.getMinutes() + 15);
    Enddate.setHours(Startdate.getMinutes() == '45' ? Enddate.getHours() + 1 : Enddate.getHours());
    Enddate.setSeconds('00');

    ServiceLogTimeActivitiesAppend({
      start_time: Startdate,
      end_time: Enddate,
    });
  };

  const onClickCancle = () => navigate(`/Admin/ServiceLog/Employee/${state?.id2}/Calendar/${state?.id1}`);

  const onClickDelete = () => {
    setIsOpen(true)
  };

  const onClickYes = id => {
    AdminServiceLogHttp.serviceLogDeleteCurrentLinks(id).then(() => afterDelete());
    setIsOpen(false);
  };

  useEffect(() => {
    setValue(`service_id`, clientsDataServiceCurrent);
  }, [servicesData, clientsDataServiceCurrent]);

  useEffect(() => {
    AdminServiceLogHttp.serviceLogGetCurrentLinks(params?.id).then(e => {
      setData(e?.data?.data);
    });
  }, []);

  useEffect(() => {
    data?.mileReimbursement?.length && setMapInputActive(true);
    data?.mileReimbursement?.length && setDistance(data?.mileReimbursement?.[1]);
  }, [data]);

  useEffect(() => {
    AdminServiceLogHttp.getClientsLinks(params?.employe_id).then(e => {
      setClientsData(e?.data?.data);
    });
    setClientsDataCurrent(data?.school_id);
    setClientsDataServiceCurrent(data?.service_id);
  }, [data]);

  useEffect(() => {
    clientsDataCurrent &&
      AdminServiceLogHttp.getServicesLinks(clientsDataCurrent, params?.employe_id).then(e => {
        setServiesData(e?.data?.data);
      });
      !!clientsDataCurrent && setIsRush(clientsData?.find(elem => elem?.id == clientsDataCurrent)?.eligibleRush);
      !!clientsDataCurrent && setOverTime(clientsData?.find(elem => elem?.id == clientsDataCurrent)?.eligibleOvertime);
      !!clientsDataCurrent && setHasServiceType(clientsData?.find(elem => elem?.id == clientsDataCurrent)?.hasServiceType);
  }, [clientsDataCurrent,clientsData]);

  useEffect(() => {
    clientsDataCurrent &&
      clientsDataServiceCurrent &&
      AdminServiceLogHttp.getPositionsLinks(clientsDataServiceCurrent, clientsDataCurrent, params?.employe_id).then(
        e => {
          setPositionsData(e?.data?.data);
        },
      );
  }, [clientsDataServiceCurrent, clientsDataCurrent]);

  useEffect(() => {
    tazaData?.latitude1 &&
      tazaData?.latitude2 &&
      serviceLogHttp.serviceLogDeferenceLinks(tazaData).then(e => {
        e.data.id = data?.mileReimbursement?.[data?.mileReimbursement?.length - 1]?.id;
        setDistance(e?.data);
      });
  }, [data1, data2]);

  useEffect(() => {
    reset(EditServiceLogAdminDefaultValue(data));
  }, [data,clientsData]);

  const more4Hour = watch('serviceLogTimeActivities')?.some(e => e?.currentMinError === true);
  const min15Step = watch('serviceLogTimeActivities')?.some(e => e?.currentStepMinError === true);
  const activitesCount = watch('serviceLogTimeActivities');

  useEffect(() => {
    activitesCount?.length == 1 && setDiableAdd(false);
    activitesCount?.length == 1 && setErrorss(false);
  }, [activitesCount]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={params?.id}
        text="Are you sure you want to delete this Service Log"
      />
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Edit Service Log For ${data?.employee}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Client Company'}
                selectData={clientsData}
                selectClass="flex-col gap-2"
                onChange={onChangeClientsData}
                name={'school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Service'}
                selectData={servicesData}
                selectClass="flex-col gap-2"
                onChange={onChangeServiceData}
                name={'service_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.service_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'Date'}
                register={register}
                name={'date'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.date?.message}</p>}
            </div>
          </div>
          {ServiceLogTimeActivitiesFields.map((elem, index) => (
            <div key={elem?.id} className="grid sm:px-7 px-4 grid-cols-1 gap-2 mt-5">
              <div className="flex flex-row gap-2 md:w-1/2 md:pr-2">
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="Start Time"
                    setValue={setValue}
                    value={getValues(`serviceLogTimeActivities.${index}.start_time`)}
                    name={`serviceLogTimeActivities.${index}.start_time`}
                    control={control}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.start_time?.message}
                    </p>
                  )}
                </div>
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="End Time"
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.end_time`}
                    control={control}
                    value={getValues(`serviceLogTimeActivities.${index}.end_time`)}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.end_time?.message}
                    </p>
                  )}
                </div>
                {!!isRush && (
                  <div className="flex items-center">
                    <div className="flex h-6 items-center">
                      <input
                        aria-describedby="comments-description"
                        {...register(`serviceLogTimeActivities.${index}.rush`)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label className="font-medium text-[#83898C]">Rush</label>
                    </div>
                  </div>
                )}
              </div>
              {!!hasServiceType && (
                <ServiceTypeAndCaseAdmin
                  errors={errors}
                  index={index}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  clientsDataCurrent={clientsDataCurrent}
                  clientsDataServiceCurrent={clientsDataServiceCurrent}
                  currentServiceType={getValues(`serviceLogTimeActivities.${index}.service_type_id`)}
                  currentNewCase={getValues(`serviceLogTimeActivities.${index}.new_case_id`)}
                  employee_id={params?.employe_id}
                />
              )}
              <div className="grid md:grid-cols-2 gap-4">
                <div className="w-full bg-white mt-2">
                  <Select
                    register={register}
                    title={'Position'}
                    selectData={positionsData}
                    selectClass="flex-col gap-2"
                    name={`serviceLogTimeActivities.${index}.position_id`}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs"
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.position_id?.message}
                    </p>
                  )}
                </div>
                <div className="mt-2">
                  <CreateableSelect
                    register={register}
                    getValues={getValues}
                    defaultOption={servicesData?.filter(item => item.id == clientsDataServiceCurrent)?.[0]?.activities}
                    control={control}
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.activiteee`}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.activiteee?.value?.message ||
                        errors?.serviceLogTimeActivities?.[index]?.activiteee?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <TimerDifference
                  setErrorss={setErrorss}
                  setValue={setValue}
                  index={index}
                  nameStart={`serviceLogTimeActivities.${index}.start_time`}
                  nameEnd={`serviceLogTimeActivities.${index}.end_time`}
                  control={control}
                />
              </div>
              <>
                {index !== 0 && index == ServiceLogTimeActivitiesFields.length - 1 && (
                  <TotalTime
                    control={control}
                    index={index}
                    setErrorss={setErrorss}
                    setDiableAdd={setDiableAdd}
                    isOverTime={isOverTime}
                  />
                )}
              </>
              <div className="flex justify-end">
              <section
                className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] cursor-pointer ${
                  (diableAdd || errorss || more4Hour) && `bg-[#A6ADC9]`
                } `}
                onClick={() =>
                  onClickAddNewTimeActivity(
                    getValues(
                      `serviceLogTimeActivities.${getValues(`serviceLogTimeActivities`)?.length - 1}.end_time`,
                    ),
                  )}
                >
                <button
                  className={`text-[#4885ED] text-sm font-normal ${
                    (diableAdd || errorss || more4Hour) && `text-[white]`
                  }`}
                  type="button"
                  disabled={diableAdd || errorss || more4Hour}
                  >
                  Add New Time Activity
                </button>
              </section>
            </div>
              {ServiceLogTimeActivitiesFields.length > 1 && (
                <div className="flex flex-col gap-6">
                  <div className="flex justify-end">
                    <section
                      className="border rounded px-3 py-1  text-center min-w-[204px] max-w-[204px] cursor-pointer"
                      onClick={() => {
                        ServiceLogTimeActivitiesRemove(index);
                      }}>
                      <button className="text-[#4885ED] text-sm font-normal" type="button">
                        Remove Time Activity
                      </button>
                    </section>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="sm:px-7 px-4 mt-5">
            {errorWhenAprrove && <p className="mb-0 text-sm text-amber-800">{errorWhenAprrove}</p>}
           
            {isMileageReimbursement && (
              <div className="flex mt-5 justify-end">
                <section className="border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px]">
                  <button
                    className="text-[#4885ED] text-sm font-normal"
                    type="button"
                    onClick={() => {
                      setMapInputActive(!mapInputActive);
                      setDistance({ distance: 0 });
                    }}>
                    {mapInputActive ? 'Remove Mile reimbursement' : '+ Add  Mile reimbursement'}
                  </button>
                </section>
              </div>
            )}
            {mapInputActive && (
              <div>
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="">
                    <MapInput
                      title="From"
                      setData={setData2}
                      data={data?.mileReimbursement?.[data?.mileReimbursement?.length - 1]}
                    />
                  </div>
                  <div className="">
                    <MapInput
                      title="To"
                      setData={setData1}
                      data={data?.mileReimbursement?.[data?.mileReimbursement?.length - 1]}
                      second
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center mt-4">
                  <img src="/MilImage.svg" alt="img" />
                  <p className="text-base font-medium text-[#95969B]">{`${distance?.distance ?? 0} Mile`}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-6">
            <CancleAdd
              buttonText={'Save'}
              onClickCancle={onClickCancle}
              disabled={errorss || errorTime || more4Hour || min15Step || isLoading}
              isDelete={true}
              onClickDelete={onClickDelete}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
