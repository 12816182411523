

export const EditServiceLogAdminDefaultValue = (data) => {

    return{
        school_id: data?.school_id,
        service_id: data?.service_id,
        date: data?.date?.slice(0, 10),
        serviceLogTimeActivities: data?.["time activities"]?.map((e, _index) => {
            const Startdate = new Date()
            Startdate.setHours(e?.["Start Time"]?.includes("pm") ? +`${e?.["Start Time"]?.match(/\d+/)?.[0]}` == 12 ? `${e?.["Start Time"]?.match(/\d+/)?.[0]}` : +`${e?.["Start Time"]?.match(/\d+/)?.[0]}` + 12 : +`${e?.["Start Time"]?.match(/\d+/)?.[0]}`)
            Startdate.setMinutes(`${e?.["Start Time"]?.match(/:(\d+)/)?.[0].replace(":", "")}`)
            Startdate.setSeconds('00');
            const Enddate = new Date()
            Enddate.setHours(e?.["End Time"]?.includes("pm") ? +`${e?.["End Time"]?.match(/\d+/)?.[0]}`==12? +`${e?.["End Time"]?.match(/\d+/)?.[0]}`: +`${e?.["End Time"]?.match(/\d+/)?.[0]}` + 12 : +`${e?.["End Time"]?.match(/\d+/)?.[0]}`)
            Enddate.setMinutes(`${e?.["End Time"]?.match(/:(\d+)/)?.[0].replace(":", "")}`)
            Enddate.setSeconds('00');
            return {
                id: e?.id,
                activiteee: { value: e?.activity?.[0].value || e?.activity?.[0].label, label: e?.activity?.[0].label },
                start_time: Startdate,
                end_time: Enddate,
                position_id: e?.position_id,
                service_type_id: e?.service_type_id || "",
                new_case_id: e?.new_case_id || "",
                rush: e?.rush,
            }
        })
    }
}

export default EditServiceLogAdminDefaultValue