

export const CreateCaseDefaultValue = (clientsData) => {

    return {
        school_id: clientsData?.[0]?.id,
        students : [{name:""}],
        status : 1,
        cases: [{}],
    }
}

export default CreateCaseDefaultValue
