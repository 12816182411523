



export const AssignRequestToEmployeeDefaultValue = (data) => {


    return {
        employee_ids:data?.employee_ids,
        deadline: data?.deadline,
    }
}

export default AssignRequestToEmployeeDefaultValue
