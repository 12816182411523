import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import TableClients from '../../components/DataTable/TableClients';

import { columnsRequestTypesClient } from '././helpers/RequestTypesClientUtils'
import requestHttp from '../../http/requestHttp/requestHttp';

export default function RequestTypesClient() {
  const [requestTypesList, setRequestTypesList] = useState([]);
  const [requestTypesListPage, setRequestTypesListPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  const fetchCompanyForms = async (pageId = '') => {
    setLoading(true);
    try {
      const response = await requestHttp.getRequestFormsClient(pageId);
      setRequestTypesList(response?.data?.data || []);
      setRequestTypesListPage(response?.data || {});
    } catch (err) {
      console.error('Error fetching company forms:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyForms();
  }, []);

  const handlePaginationClick = (id) => {
    fetchCompanyForms(id);
  };
  const onClickFill = (id) => {
    navigate(`/Client/FillForm/${id}`);
  };

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <TitleBlok textItem="Request Types" className="text-base font-semibold" src="/CompanyFormsIcon.svg" />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className={`w-full overflow-x-auto  ${loading && `animate-pulse`}`}>
            <div className="inline-block min-w-full">
              <TableClients tbody={requestTypesList} columns={columnsRequestTypesClient(onClickFill)} maxHeight="500px" />
            </div>
        </div>
        <Pagination onPaginationClick={handlePaginationClick} paginationData={requestTypesListPage?.links} />
      </div>
    </div>
  );
}
