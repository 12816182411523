import Button from '../../components/Button/Button';
import ServicesCard from '../../components/ServicesCard/ServicesCard';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Service from '../../http/seviceHttp/seviceHttp';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editServiceSlice } from '../../redux/serviceSlice/serviceSlice';
import Pagination from '../../components/Pagination/Pagination';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import { columnsService, onClickAddService } from './helpers/ServicesUtils';

export default function Services() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  
  const EditClick = id => {
    dispatch(editServiceSlice(id));
    navigate(`/Admin/EditService/${id}`);
  };

  const onPaginationClick = id => {
    Service?.servicePaginationLinks(id).then(data => {
      setData(data?.data);
    });
  };

  useEffect(() => {
    Service.serviceLinks()
      .then(res => setData(res.data))
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className="w-full sm:p-8 p-2">
      <TitleBlok
        src="/TitleBlokIcon.svg"
        textItem={'services'}
        classNameTitleBlock="mb-8"
        className={'text-base capitalize font-semibold'}>
        <Button onClick={() => onClickAddService(navigate)} buttonText={'+ Add Service'} className={'text-[#fff]'} />
      </TitleBlok>
      <div className="grid md:grid-cols-2 sm:gap-6 gap-2 overflow-x-auto	max-h-[400px]">
        {data?.data?.map(e => (
          <div key={e.id} className="grow">
            <ServicesCard
              image={e.image}
              onClick={() => EditClick(e.id)}
              employees={e.employees}
              title={e.name}
              servicesCardItem={e?.servicesCardItem}
              text={e?.details}
              clients={e.clients}
              id={e?.id}
            />
          </div>
        ))}
      </div>
      <div className="mt-8">
        <TitleBlok
          src="/TitleBlokIcon.svg"
          textItem={'Service List'}
          className="capitalize font-semibold"
          classNameTitleBlock="border"
        />
      </div>
      <div className="mt-4">
        <div className="px-4 py-1 bg-white rounded-xl ">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableEmployeesAdmin tbody={data?.data ?? []} columns={columnsService(EditClick)} maxHeight='400px'/>
            </div>
          </div>
          <div>
            <Pagination paginationData={data?.links} onPaginationClick={onPaginationClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
