import { createSlice } from '@reduxjs/toolkit';
import ClientHttp from '../../http/clientsHttp/clientsHttp';

const clientsSlice = createSlice({
  name: 'clientsSlice',
  initialState: {
    clientSliceData: [],
    parentClientSliceData: [],
    parentClientSliceDataAll: [],
    parentClientCasesSliceDataAll: [],
    statesCitiesData: [],
    statesSliceData: [],
    storeDestrictData: [],
    storeStatusesData: [],
    storeEditParentData: [],
    error: '',
    storeClientData: [],
    storeAllClientsData: [],
    storeAllClientsCasesData: [],
  },
  reducers: {
    CLIENT_DATA(state, action) {
      state.clientSliceData = action.payload;
    },
    PARENT_CLIENT_DATA(state, action) {
      state.parentClientSliceData = action.payload;
    },
    PARENT_CLIENT_DATA_ALL(state, action) {
      state.parentClientSliceDataAll = action.payload;
    },
    PARENT_CLIENT_CASES_DATA_ALL(state, action) {
      state.parentClientCasesSliceDataAll = action.payload;
    },
    STATES_DATA(state, action) {
      state.statesSliceData = action.payload;
    },
    CITIES_DATA(state, action) {
      state.statesCitiesData = action.payload;
    },
    STORE_DISTRICT(state, action) {
      state.storeDestrictData = action.payload;
    },
    STATUSES_DATA(state, action) {
      state.storeStatusesData = action.payload;
    },
    EDIT_PARENT_DATA(state, action) {
      state.storeEditParentData = action.payload;
    },
    ERROR_DATA(state, action) {
      state.error = action.payload;
    },
    EDIT_CLIENT_DATA(state, action) {
      state.storeClientData = action.payload;
    },
    GET_ALL_CLIENTS_DATA(state, action) {
      state.storeAllClientsData = action.payload;
    },
    GET_ALL_CLIENTS_CASES_DATA(state, action) {
      state.storeAllClientsCasesData = action.payload;
    },
  },
});

export const loadClientSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.clientsLinks();
    dispatch(CLIENT_DATA(data.data));
  } catch {
    console.log('Error Clients');
  }
};
export const loadPaginationClientSlice = id => async dispatch => {
  try {
    const data = await ClientHttp.paginationClientsLinks(id);
    dispatch(CLIENT_DATA(data.data));
  } catch {
    console.log('Error Clients');
  }
};
export const loadPaginationParentsSlice = id => async dispatch => {
  try {
    const data = await ClientHttp.paginationParentsLinks(id);
    dispatch(PARENT_CLIENT_DATA(data.data));
  } catch {
    console.log('Error Clients');
  }
};
// all parents
export const loadParentsSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.parentClientsLinks();
    dispatch(PARENT_CLIENT_DATA_ALL(data.data));
  } catch {
    console.log('Error Clients');
  }
};
export const loadParentsCasesSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.parentClientsCasesLinks();
    dispatch(PARENT_CLIENT_CASES_DATA_ALL(data.data));
  } catch {
    console.log('Error Clients');
  }
};
export const loadStatesSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.statesLinks();
    dispatch(STATES_DATA(data.data));
  } catch {
    console.log('Error Clients');
  }
};
export const loadCitiesSlice = id => async dispatch => {
  try {
    const data = await ClientHttp.citiesLinks(id);
    await dispatch(CITIES_DATA(data.data));
  } catch {
    console.log('Error Sities');
  }
};
export const loadStoreDistrictSlice = data1 => async dispatch => {
  try {
    const data = await ClientHttp.storeDistrictLinks(data1);
    dispatch(STORE_DISTRICT(data.data));
    dispatch(ERROR_DATA(''));
  } catch (error) {
    dispatch(ERROR_DATA(error?.response?.data?.message));
  }
};

export const loadStatusesSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.statusesLinks();
    await dispatch(STATUSES_DATA(data.data));
  } catch {
    console.log('Error STATUSES_DATA');
  }
};
export const editParentCompanySlice = id => async dispatch => {
  try {
    const data = await ClientHttp.editParentCompany(id);
    await dispatch(EDIT_PARENT_DATA(data.data));
  } catch {
    console.log('Erro Parent Company Edit');
  }
};
// export const storeEditParentCompanySlice = (id, editData) => async (dispatch) => {
//   try {
//     const data = await ClientHttp.parentCompanyEditLinks(id, editData)
//     await dispatch(EDIT_PARENT_DATA(data.data.data));
//     await dispatch(ERROR_DATA(''))
//     await dispatch(loadStatesSlice())
//     await dispatch(editParentCompanySlice(id))
//   } catch (error) {
//     dispatch(ERROR_DATA(error?.response?.data?.message));
//   }
// };

export const editClientSlice = id => async dispatch => {
  try {
    const data = await ClientHttp.getClient(id);
    await dispatch(EDIT_CLIENT_DATA(data.data));
  } catch {
    console.log('Erro Client Edit');
  }
};
// export const storeEditClientSlice = (id, editData) => async (dispatch) => {

//   try {
//     const data = await ClientHttp.clientEditLinks(id, editData)
//     await dispatch(EDIT_CLIENT_DATA(data.data.data));
//     await dispatch(ERROR_DATA(''))
//   } catch (error) {
//     dispatch(ERROR_DATA(error?.response?.data?.message));
//   }
// };

export const getAllClientsSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.getAllClientss();
    await dispatch(GET_ALL_CLIENTS_DATA(data.data.data));
  } catch {
    console.log('Erro Client Edit');
  }
};
export const getAllClientsCasesSlice = () => async dispatch => {
  try {
    const data = await ClientHttp.getAllClientsCases();
    await dispatch(GET_ALL_CLIENTS_CASES_DATA(data.data.data));
  } catch {
    console.log('Erro Client Edit');
  }
};

export const {
  CLIENT_DATA,
  PARENT_CLIENT_DATA,
  GET_ALL_CLIENTS_DATA,
  GET_ALL_CLIENTS_CASES_DATA,
  STATES_DATA,
  CITIES_DATA,
  STORE_DISTRICT,
  STATUSES_DATA,
  PARENT_CLIENT_DATA_ALL,
  PARENT_CLIENT_CASES_DATA_ALL,
  EDIT_PARENT_DATA,
  ERROR_DATA,
  EDIT_CLIENT_DATA,
} = clientsSlice.actions;
export default clientsSlice.reducer;
