export const EditCaseDefaultValue = dataDefalt => {

  return {
    school_id: dataDefalt?.school_id,
    teacher: dataDefalt?.teacher,
    status: dataDefalt?.status,
    students:
      dataDefalt?.students?.length > 0
        ? dataDefalt?.students?.map(e => ({
            id: e?.id,
            name: e?.name,
          }))
        : [{ name: '' }],
    cases:
      dataDefalt?.cases?.length > 0
        ? dataDefalt?.cases?.map(e => ({
            id: e?.id,
            service_id: e?.service_id,
            has_logs:e?.has_logs,
            employees: e?.employees,
            service_type_id: e?.service_type_id,
          }))
        : [{ }],
  };
};

export default EditCaseDefaultValue;
