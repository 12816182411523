import * as yup from 'yup';

export const RequestErrorEditServiceLog = (hasServiceType) => {
  const formSchemaForServiceLog = {
    start_time: yup.date().required('This field is required'),
    end_time: yup.date('This field is required').required('This field is required'),
    activiteee: yup
      .object()
      .shape({
        label: yup.string().required('This field is required'),
        value: yup.string().required('This field is required'),
      })
      .required('This field is required'),
    position_id: yup.string().required('This field is required'),
    service_type_id: hasServiceType ? yup.string().required('This field is required') : yup.string(),
    new_case_id: hasServiceType ? yup.string().required('This field is required') : yup.string(),
  };
  return yup.object().shape({
    school_id: yup.string().required('This field is required'),
    service_id: yup.string().required('This field is required'),

    date: yup.string().required('This field is required'),
    serviceLogTimeActivities: yup.array().of(yup.object().shape(formSchemaForServiceLog)),
  });
};

export default RequestErrorEditServiceLog;
