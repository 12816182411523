import { useEffect, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsSlice, loadParentsSlice } from '../../redux/clientsSlice/clientsSlice';
import Select from '../../components/Select/Select';

import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';

import ClientHttp from '../../http/clientsHttp/clientsHttp';
import PaymentsHttp from '../../http/PaymentsHttp/PaymentsHttp';
import { columnsSendCustomLogsAdmin } from './helpers/RemaindPaymentUtils';
export default function RemindPayment() {
  const [isLoading, setIsLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(false)
  // const [fileUrl, setFileUrl] = useState()
  const [allParentsSelectData, setAllParentsSelectData] = useState();
  const [allParentsClients, setAllParentsClients] = useState([]);
  const [currentParentId, setCurrentParentId] = useState('');
  const [currentSchoolId, setCurrentSchoolId] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  const dispatch = useDispatch();
  const clientsData = useSelector(state => state?.clients?.storeAllClientsData);

  const allParents = useSelector(state => state?.clients?.parentClientSliceDataAll?.data);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    // defaultValues: useMemo(() => RemindPaymentDefaultValue(), []),
    // resolver: yupResolver(RequestErrorCreateInvoice()),
  });
  const navigate = useNavigate();

  const [isError, setIsError] = useState();
  const [checked, setChecked] = useState([]);

  // const [currentParentId, setCurrentParentId] = useState()
  // const [currentSchoolId, setCurrentSchoolId] = useState()
  const [tbodyData, setTbodyData] = useState([]);

  const afterSubmit = () => {
    notifySuccess('Message successfully sent');
    setTimeout(() => {
      navigate('/Admin/Payments');
    }, 2000);
  };
  
  const onSubmit = data => {
    setIsLoading(true);
    const sendedData = {
      school_id: data?.school_id || '',
      group_school_id: data?.group_school_id || '',
      selectedIds: checked,
    };
    PaymentsHttp.remindInvoices(sendedData)
      .then(() => afterSubmit())
      .catch(err => {
        setIsError(err?.response?.data?.message);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/Payments');
  };

  const onChangeSchoolId = e => {
    setCurrentSchoolId(e.target.value);
    setValue('school_id', e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };
  const onChangeStatus = e => {
    setCurrentStatus(e.target.value);
    setValue('status', e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };
  const onChangeParentSchoolId = e => {
    setCurrentSchoolId('');
    setValue('school_id', '');
    setCurrentParentId(e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const handleMasterCheckboxChange = e => {
    const isChecked = e.target.checked;
    const allCheckboxIds = tbodyData.map(item => ({ id: item.id })); // Assuming your data has unique IDs
    if (isChecked) {
      setChecked(allCheckboxIds);
      // Object.keys(getValues()).map((item, i) => (
      //     Number(item) ? setValue(`${Number(item)}`, false) : item
      // ))
      tbodyData?.length > 0 && tbodyData?.map(item => setValue(`${Number(item?.id)}`, true));
    } else {
      setChecked([]);
      tbodyData?.length > 0 && tbodyData?.map(item => setValue(`${Number(item?.id)}`, false));
    }
  };

  useEffect(() => {
    // reset(SendInvoicesDefaultValue());
    dispatch(getAllClientsSlice());
    dispatch(loadParentsSlice());
  }, []);

  useEffect(() => {
    reset({ school_id: currentSchoolId, group_school_id: currentParentId, status: currentStatus });
    setValue('school_id', currentSchoolId);
    setValue('group_school_id', currentParentId);
    setValue('status', currentStatus);
  }, [tbodyData]);

  useEffect(() => {
    currentParentId || currentSchoolId
      ? PaymentsHttp.getUnpaidInvoices(currentSchoolId, currentParentId, currentStatus).then(e =>
          setTbodyData(e?.data?.data),
        )
      : setTbodyData([]);
  }, [currentSchoolId, currentParentId, currentStatus]);

  useEffect(() => {
    setValue('school_id', '');
    setCurrentSchoolId('');
  }, [clientsData]);

  useEffect(() => {
    const aaa = allParents?.map(item => ({
      name: item?.['Client Name'],
      id: item?.id,
    }));
    aaa?.unshift({ id: '', name: 'Not Selected' });
    setAllParentsSelectData(aaa);
  }, [allParents]);

  useEffect(() => {
    currentParentId &&
      ClientHttp.getClientsByParent(currentParentId).then(e => {
        const data = e?.data?.data;
        data?.unshift({ id: '', name: 'Not Selected' });
        setAllParentsClients(data);
        setValue('school_id', '');
        setCurrentSchoolId('');
      });
    // :
    // setValue("school_id", clientsData?.[0]?.id)
    // setCurrentSchoolId(clientsData?.[0]?.id)
  }, [currentParentId]);

  useEffect(() => {
    if (tbodyData?.length) {
      checked?.length === tbodyData?.length ? setValue('masterCheckbox', true) : setValue('masterCheckbox', false);
    }
  }, [checked, tbodyData]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Remind Payments'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          {allParentsSelectData && (
            <div className="w-full bg-white sm:px-8 px-2">
              <Select
                register={register}
                title={'Select Parent Company'}
                selectData={allParentsSelectData}
                selectClass="flex-col gap-2"
                onChange={onChangeParentSchoolId}
                name={'group_school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_school_id?.message}</p>}
            </div>
          )}
          <div className="py-4 sm:px-7 px-2 w-full flex gap-6">
            {currentParentId == '' ? (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={clientsData}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            ) : (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={allParentsClients}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            )}
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Select Status'}
                selectData={[
                  { id: '', name: 'All' },
                  { id: 3, name: 'Past Due' },
                  { id: 2, name: 'Partial Paid' },
                ]}
                selectClass="flex-col gap-2"
                name={'status'}
                onChange={onChangeStatus}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.status?.message}</p>}
            </div>
          </div>
          <div>
            <div className="px-4 py-1 sm:m-4 mx-4 my-6 bg-white rounded-xl">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={tbodyData ?? []}
                    columns={columnsSendCustomLogsAdmin(
                      register,
                      handleMasterCheckboxChange,
                      tbodyData,
                      checked,
                      setChecked,
                    )}
                    maxHeight='500px'
                  />
                </div>
              </div>
            </div>
          </div>
          {isError && <p className="text-base font-medium text-[red] py-4 sm:px-7 px-2">{isError}</p>}
          <div>
            <CancleAdd
              disabled={isLoading || checked?.length < 1}
              buttonText={`Remind`}
              onClickCancle={OnClickCancle}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
