import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';

import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { formatStringForName, notifySuccess } from '../../utils/utils';
import InputLabel from '../../components/InputLabel/InputLabel';
import requestHttp from '../../http/requestHttp/requestHttp';
import FormFields from './components/FormFields';
import EditRequestFormDefaultValue from './EditRequestFormDefaultValue';
import RequestErrorEditRequestForm from './RequestErrorEditRequestForm';

import 'react-toastify/dist/ReactToastify.css';

export default function EditRequestForm() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    reset,
    getValues,
    unregister,
  } = useForm({
    defaultValues: useMemo(() => EditRequestFormDefaultValue(data), [data]),
    resolver: yupResolver(RequestErrorEditRequestForm()),
  });
  const navigate = useNavigate();

  const afterSubmit = () => {
    notifySuccess('Request Form successfully edited');
    setTimeout(() => {
      navigate('/Admin/RequestForms');
    }, 2000);
  };

  const onSubmit = async data => {
    const SendData = {
      title: data?.title,
      fields: data?.fields?.map((elem, index) => ({
        id: elem?.id,
        label: elem?.label,
        name: formatStringForName(elem?.label),
        is_required: true,
        type: elem?.type,
        options:
          elem?.type === 'checkbox'
            ? elem?.options?.map((item, indexOpt) => ({
                name: item?.name,
              }))
            : null,
      })),
    };
    setIsLoading(true);
    requestHttp
      ?.editRequestForm(params?.id, SendData)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/RequestFroms');
  };

  const {
    fields: Fields,
    append: FieldsAppend,
    remove: FieldsRemove,
  } = useFieldArray({
    control,
    name: 'fields',
  });

  const onClickAddNewField = () => {
    FieldsAppend({
      type: '',
      label: '',
    });
  };

  useEffect(() => {
    params?.id &&
      requestHttp.getRequestForm(params?.id).then(e => {
        setData(e?.data?.data);
      });
  }, []);

  useEffect(() => {
    reset(EditRequestFormDefaultValue(data));
  }, [data]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Edit Request Form'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-end">
              <div className="mt-5">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Request Form Title'}
                  placeholder={'Request Title'}
                  register={register}
                  name={`title`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.title?.message}</p>}
              </div>
            </div>
            <div>
              {Fields.map((elem, index) => (
                <Fragment key={elem?.id}>
                  <FormFields
                    register={register}
                    errors={errors}
                    index={index}
                    FieldsRemove={FieldsRemove}
                    onClickAddNewField={onClickAddNewField}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    unregister={unregister}
                  />
                </Fragment>
              ))}
            </div>
          </div>
          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Save`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
