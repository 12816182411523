import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Pagination from '../../components/Pagination/Pagination';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Tub from '../../components/Tab/Tab';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editClientSlice,
  editParentCompanySlice,
  loadPaginationClientSlice,
  loadPaginationParentsSlice,
} from '../../redux/clientsSlice/clientsSlice';
import Modal from '../../components/Modal/Modal';
import TableParent from '../../components/DataTable/TableParent';
import TableClients from '../../components/DataTable/TableClients';
import { ToastContainer } from 'react-toastify';

export default function Clients() {
  const [isActive, setIsActive] = useState(1);
  const [reload, setReload] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const clientData = useSelector(data => data.clients.clientSliceData);
  const parentsData = useSelector(data => data?.clients?.parentClientSliceData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columnsParent = useMemo(
    () => [
      {
        accessorKey: 'Client Name',
        header: 'Client Name', //uses the default width from defaultColumn prop
        Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['Client Name']}</p>,
      },
      {
        accessorFn: row => `${row?.contacts?.[0]?.position}`,
        // id: 'name',
        header: 'Position', //uses the default width from defaultColumn prop
        Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contacts?.[0]?.position}</p>,
      },
      {
        accessorFn: row => `${row?.contacts?.[0]?.contactName}`,
        header: 'Contact Name', //uses the default width from defaultColumn prop
        Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contacts?.[0]?.contactName}</p>,
      },
      {
        accessorFn: row => `${row?.contacts?.[0]?.contact_phones?.[0]?.phone}`,
        header: 'Phone Number', //uses the default width from defaultColumn prop
        Cell: ({ row }) => (
          <p className="whitespace-nowrap">{row?.original?.contacts?.[0]?.contact_phones?.[0]?.phone}</p>
        ),
      },
      {
        accessorKey: 'Action',
        header: 'Action',
        size: 230, //decrease the width of this column
        // maxSize: 100,
        Cell: ({ row }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              minWidth: '220px',
            }}>
            {/* <button
              className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
              onClick={() => editParentCompany(row?.original?.id)}>
              Edit
            </button> */}
            <img
              onClick={() => editParentCompany(row?.original?.id)}
              className="cursor-pointer"
              src="/EditIcon.png"
              alt="EditIcon"
            />
            {row?.original?.access == 1 || row?.original?.add_emails?.length > 0 ? (
              <button
                className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
                // onClick={() => onClickAccountStatus({ id: row?.original?.id, verified: row?.original?.verified, verified_add_email: row?.original?.add_emails?.[0]?.otp_verified, email: row?.original?.email, add_email: row?.original?.add_emails, client_name: row?.original?.name,add_school_id:row?.original?.add_emails?.[0]?.id })}
                onClick={() => navigate(`/Admin/EditAccessParent/${row?.original?.id}`)}>
                {/* <img className="max-w-[60px] h-[20px] block cursor-pointer" src="/Done.svg" alt="Done" /> */}
                Account Status
              </button>
            ) : (
              <button
                className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900 "
                onClick={() => giveAccessParent(row?.original?.id)}>
                Send account invite
              </button>
            )}
          </div>
        ),
      },
    ],
    [],
  );
  const columnsClient = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Client Name', //uses the default width from defaultColumn prop

        // hishem avelacnem
        Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
      },
      {
        accessorKey: `contact`,
        // id: 'name',
        size: 65,
        header: 'Contact', //uses the default width from defaultColumn prop
        Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contact}</p>,
      },
      {
        accessorFn: row => `${row?.positions}`,
        header: 'Positions', //uses the default width from defaultColumn prop
        Cell: ({ row }) =>
          row?.original?.positions?.map(({ name }, index) => (
            <p key={index} className="whitespace-nowrap">
              {name}
            </p>
          )),
      },
      {
        accessorFn: row => `${row?.services}`,
        header: 'Services', //uses the default width from defaultColumn prop
        Cell: ({ row }) =>
          row?.original?.services?.map(({ name }, index) => (
            <p key={index} className="whitespace-nowrap">
              {name}
            </p>
          )),
      },
      {
        accessorFn: row => `${row?.services}`,
        size: 50,
        header: 'Price', //uses the default width from defaultColumn prop
        Cell: ({ row }) =>
          row?.original?.services?.map(({ price }, index) => (
            <p key={index} className="whitespace-nowrap">
              ${price}
            </p>
          )),
      },
      {
        accessorKey: 'type',
        size: 55,
        header: 'Type', //uses the default width from defaultColumn prop
        Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.type}</p>,
      },
      {
        accessorKey: `status`,
        size: 55,
        header: 'Status', //uses the default width from defaultColumn prop
        // person?.status ==1 ? "Active" : person?.status == 2 ? "In Proccess" : "Inactive",
        Cell: ({ row }) => (
          <span
            className={` ${
              row?.original?.status == 1
                ? 'bg-[#F0FCFD] text-[#00B1B2] ring-[#C4EAD9]'
                : row?.original?.status == 2
                ? 'bg-blue-50 text-blue-700 ring-blue-700/10'
                : 'bg-gray-50 text-gray-600 ring-gray-500/10'
            }
         inline-flex items-center rounded-md 
          ring-1 ring-inset  px-2 py-1 text-xs font-medium
         `}>
            {row?.original?.status == 1 ? 'Active' : row?.original?.status == 2 ? 'In Proccess' : 'Inactive'}
          </span>
        ),
      },
      {
        accessorKey: 'Action',
        header: 'Action',
        size: 230, //decrease the width of this column
        // maxSize: 100,
        Cell: ({ row }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              minWidth: '220px',
            }}>
            {/* <button
              className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
              onClick={() => editClient(row?.original?.id)}>
              Edit
            </button> */}
            <img
              onClick={() => editClient(row?.original?.id)}
              className="cursor-pointer"
              src="/EditIcon.png"
              alt="EditIcon"
            />

            {row?.original?.access == 1 || row?.original?.add_emails?.length > 0 ? (
              <button
                className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
                // onClick={() => onClickAccountStatus({ id: row?.original?.id, verified: row?.original?.verified, verified_add_email: row?.original?.add_emails?.[0]?.otp_verified, email: row?.original?.email, add_email: row?.original?.add_emails, client_name: row?.original?.name,add_school_id:row?.original?.add_emails?.[0]?.id })}
                onClick={() => navigate(`/Admin/EditAccessClient/${row?.original?.id}`)}>
                {/* <img className="max-w-[60px] h-[20px] block cursor-pointer" src="/Done.svg" alt="Done" /> */}
                Account Status
              </button>
            ) : (
              <button
                className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900 "
                onClick={() => giveAccess(row?.original?.id)}>
                Send account invite
              </button>
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const ParentData = () => {
    dispatch(loadPaginationParentsSlice(''));
    setIsActive(1);
  };
  const ClientData = () => {
    dispatch(loadPaginationClientSlice(''));
    setIsActive(0);
  };

  const onPaginationClick = id => {
    isActive ? dispatch(loadPaginationParentsSlice(id)) : dispatch(loadPaginationClientSlice(id));
  };

  const editParentCompany = id => {
    dispatch(editParentCompanySlice(id));
    navigate(`/Admin/EditParentCompany/${id}`);
  };
  const editClient = id => {
    dispatch(editClientSlice(id));
    navigate(`/Admin/EditClient/${id}`);
  };
  const giveAccess = id => {
    // dispatch(editClientSlice(id))
    navigate(`/Admin/GiveAccessClient/${id}`);
  };
  const giveAccessParent = id => {
    // dispatch(editClientSlice(id))
    navigate(`/Admin/GiveAccessParent/${id}`);
  };

  const onClickAddClient = () => setIsOpen(true);

  useEffect(() => {
    dispatch(loadPaginationParentsSlice(''));
    dispatch(loadPaginationClientSlice(''));
  }, [isActive, reload]);

  return (
    <div className="w-full mb-12 overflow-hidden">
      <Modal open={isOpen} setOpen={setIsOpen} navigateYes="/Admin/AddParentCompany" navigateNo="/Admin/AddNewClient" />
      <TitleBlok
        textItem={isActive == '1' ? 'Parent Clients List' : 'Clients List'}
        src="/Layer_1.svg"
        className={'text-base font-semibold capitalize'}
        classNameTitleBlock="sm:mx-8 mx-4 mt-8">
        <div className="flex sm:gap-4 gap-2">
          {/* <Button onClick={() => navigate('/Admin/AddParentCompany')} buttonText={"Add Parent Company"} className={"text-[#fff]"} /> */}
          <Button onClick={onClickAddClient} buttonText={'Add Client'} className={'text-[#fff] sm:text-sm text-xs'} />
        </div>
      </TitleBlok>
      <div className="mt-4">
        <Tub
          active={isActive}
          titleParent={'Parent Client'}
          titleClient={'Client'}
          clickParent={ParentData}
          clickClient={ClientData}
          priceParent={parentsData?.links?.total ?? 0}
          priceClient={clientData?.links?.total ?? 0}
        />
      </div>

      <div className="sm:px-8 px-4">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl ">
          <div className="overflow-x-auto">
            {isActive ? (
              <TableParent tbody={parentsData?.data ?? []} columns={columnsParent} maxHeight="600px" />
            ) : (
              <TableClients tbody={clientData?.data ?? []} columns={columnsClient} maxHeight="600px" />
            )}
            <div>
              <Pagination
                paginationData={isActive ? parentsData.links : clientData?.links}
                onPaginationClick={onPaginationClick}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
