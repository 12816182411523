



export const AssignRequestToClientsDefaultValue = (data) => {


    return {
        school_ids:data?.clients,
        deadline: data?.deadline,
    }
}

export default AssignRequestToClientsDefaultValue
