import * as yup from 'yup';

export const RequestErrorCreateCase = () => {
  const formSchemaForStudents = {
    name: yup.string().required('This field is required'),
  };
  const formSchemaForCases = {
    service_id: yup.string().required('This field is required'),
    service_type_id: yup.string().required('This field is required'),
    employees: yup.array().min(1).required('This field is required'),
  };
  return yup.object().shape({
    school_id: yup.string().required('This field is required'),
    status: yup.string().required('This field is required'),
    teacher: yup.string().required('This field is required'),
    students: yup.array().of(yup.object().shape(formSchemaForStudents)),
    cases: yup.array().of(yup.object().shape(formSchemaForCases)),
  });
};

export default RequestErrorCreateCase;
