import { useEffect, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import InputLabel from '../../components/InputLabel/InputLabel';
import RequestErrorCreateCompanyForm from './RequestErrorCreateCompanyForm';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import { useDropzone } from 'react-dropzone';

export default function CreateCompanyFormClients() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    // defaultValues: useMemo(() => CreateInvoiceDefaultValue(), []),
    resolver: yupResolver(RequestErrorCreateCompanyForm()),
  });

  const navigate = useNavigate();
  const [allClientsData, setAllClientsData] = useState([]);
  const [allAddClientsData, setAllAddClientsData] = useState([]);
  const [allParentClientsData, setAllParentClientsData] = useState([]);

  useEffect(() => {
    companyFormsHttp.getSchoolsForCompanyForm().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Clients' });
      setAllClientsData(employesList);
    });
    companyFormsHttp.getAddSchoolsForCompanyForm().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.email || item?.[0]?.email,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Add Clients' });
      setAllAddClientsData(employesList);
    });
    companyFormsHttp.getGroupSchoolsForCompanyForm().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Parent Clients' });
      setAllParentClientsData(employesList);
    });
  }, []);

  const afterSubmit = () => {
    notifySuccess('Company Form successfully created');
    setTimeout(() => {
      navigate('/Admin/CompanyFormsClients');
    }, 2000);
  };
  
  const onSubmit = async data => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', acceptedFiles?.[0]);
    data.file = formData.get('file');
    data.original_url = "test";
    companyFormsHttp
      ?.createCompanyFormsClient(data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/CompanyFormsClients');
  };

  const { acceptedFiles, getRootProps, getInputProps,} = useDropzone();

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Create Company Form'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-end">
              <div className="">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Form Title'}
                  placeholder={'Details'}
                  register={register}
                  name={`title`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.title?.message}</p>}
              </div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Clients'}
                  selectData={allClientsData}
                  selectClass="flex-col gap-2"
                  name={'schools'}
                  All="All Clients"
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.schools?.message}</p>}
              </div>
            </div>
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 space-y-5">
              <div className="w-full bg-white mt-5"></div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Additional Clients'}
                  selectData={allAddClientsData}
                  selectClass="flex-col gap-2"
                  name={'add_schools'}
                  All="All Add Clients"
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.add_schools?.message}</p>}
              </div>
            </div>
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 space-y-5">
              <div className="w-full bg-white mt-5"></div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Parent Clients'}
                  selectData={allParentClientsData}
                  selectClass="flex-col gap-2"
                  name={'group_schools'}
                  All="All Parent Clients"
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_schools?.message}</p>}
              </div>
            </div>
            <section className="container mt-5">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input
                  {...getInputProps()}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="file_input"
                  type="file"
                  style={{ display: 'block' }}
                />
              </div>
            </section>
            {/* <div className="mt-5">
              <Widget
                onChange={e => {
                  setState(e);
                }}
                publicKey="demopublickey"
                clearable
                tabs="file"
              />
              {!state && <p className="mb-0 text-sm text-amber-800">Please Add File</p>}
            </div> */}
          </div>
          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Send`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
