import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { EditInvoiceDefaultValue } from './EditInvoiceDefaultValue';
import { ToastContainer } from 'react-toastify';
import { notifySuccess } from '../../utils/utils';
import InvoiceHttp from '../../http/invoiceHttp/invoiceHttp';

import 'react-toastify/dist/ReactToastify.css';
import InputLabel from '../../components/InputLabel/InputLabel';

export default function EditInvoice() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const params = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: useMemo(() => EditInvoiceDefaultValue(data), [data]),
    // resolver: yupResolver(RequestErrorRegenerateInvoice()),
  });
  const navigate = useNavigate();

  const {
    fields: InvoiceDetailsFields,
  } = useFieldArray({
    control,
    name: 'invoice_details',
  });

  const afterSubmit = () => {
    notifySuccess('Invoice successfully created');
    setTimeout(() => {
      navigate('/Admin/Invoices');
    }, 2000);
  };

  const onSubmit = data => {
    setIsLoading(true);
    const sendedData = data.invoice_details?.map(item => {
      return {
        edited_id: item?.edited_id || null,
        service_id: item?.service_id,
        hours: item?.total_time,
        rate: item?.rate,
        rush: item?.rush,
        overtime: item?.overtime,
        employee_id: item?.employee_id,
        service_type: item?.has_service_type ? item?.service_type : null,
      };
    });

    InvoiceHttp.editInvoice(params?.id, { invoice_details: sendedData })
      .then(() => afterSubmit())
      .catch(err => {
        // setIsError(err?.response?.data?.message);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/Invoices');
  };

  useEffect(() => {
    reset(EditInvoiceDefaultValue(data));
  }, [data]);

  useEffect(() => {
    InvoiceHttp.getInvoiceForEdit(params?.id).then(e => setData(e?.data?.data));
  }, []);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={`Edit Invoice  `} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
            <InputLabel
              labelTitle={'Grand Total'}
              inputId={`grand_total`}
              disabled={true}
              className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
              placeholder={'Grand Total'}
              classNameInput="w-full"
              name={`grand_total`}
              register={register}
              classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
            />
          </div>
          <div className="divide-y divide-slate-200">
            {InvoiceDetailsFields.map((elem, index) => (
              <div className="mt-4" key={elem.id}>
                <p className="sm:px-7 px-4 text-lg font-semibold">Invoice {index + 1}</p>
                {/* <div className='sm:px-7 px-4 h-[1px] w-full border-1'></div> */}
                <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2">
                  <div>
                    <InputLabel
                      labelTitle={'Code'}
                      inputId={`invoice_details.${index}.code`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Code'}
                      disabled={true}
                      name={`invoice_details.${index}.code`}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                  <div>
                    <InputLabel
                      labelTitle={'Employee Name'}
                      inputId={`invoice_details.${index}.employee_name`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Employee Name'}
                      name={`invoice_details.${index}.employee_name`}
                      disabled={true}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                  <div>
                    <InputLabel
                      labelTitle={'Total Price'}
                      inputId={`invoice_details.${index}.total_price`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Total Price'}
                      name={`invoice_details.${index}.total_price`}
                      // onKeyPress={onlyLetters}
                      disabled={true}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                </div>
                <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 mt-4">
                  <div>
                    <InputLabel
                      labelTitle={'Client Name'}
                      inputId={`invoice_details.${index}.school_name`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Client Name'}
                      disabled={true}
                      name={`invoice_details.${index}.school_name`}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                  <div>
                    <InputLabel
                      labelTitle={'Service Name'}
                      inputId={`invoice_details.${index}.service_name`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Service Name'}
                      disabled={true}
                      name={`invoice_details.${index}.service_name`}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                 {elem?.has_service_type && <div>
                    <InputLabel
                      labelTitle={'Service Type'}
                      inputId={`invoice_details.${index}.service_type`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'ServiceType'}
                      name={`invoice_details.${index}.service_type`}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>}
                </div>
                <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 mt-4">
                  <div>
                    <InputLabel
                      labelTitle={'Total Time'}
                      inputId={`invoice_details.${index}.total_time`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Total Time'}
                      name={`invoice_details.${index}.total_time`}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                  <div>
                    <InputLabel
                      labelTitle={'Rate'}
                      inputId={`invoice_details.${index}.rate`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Rate'}
                      name={`invoice_details.${index}.rate`}
                      // onKeyPress={onlyLetters}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div>
            <CancleAdd disabled={isLoading} buttonText={`Edit Invoice`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
