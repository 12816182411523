import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import EmployeeDashboardHttp from '../../http/employeeDashboardHttp/employeeDashboardHttp';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import { adminProfileDataSlice, employeeProfileDataSlice } from '../../redux/adminProfileSlice/adminProfileSlice';
import DashboardCardEmployee from '../../components/DashboardCardEmployee/DashboardCardEmployee';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import { columnsDashboardEmployee, columnsDashboardEmployeeCase } from './helpers/DashboardEmployeeUtils';
import TableClients from '../../components/DataTable/TableClients';

export default function DashboardEmployee() {
  const [services, setServices] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [cases, setCases] = useState([]);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        if (role === 'admin') {
          dispatch(adminProfileDataSlice());
        } else {
          dispatch(employeeProfileDataSlice());
        }
      }

      try {
        const [servicesResponse, assignmentsResponse,casesResponse] = await Promise.all([
          EmployeeDashboardHttp.employeeServicesLinks(),
          EmployeeDashboardHttp.employeeAssignmentsLinks(),
          EmployeeDashboardHttp.employeeCasesLinks()
        ]);

        setServices(servicesResponse?.data?.data || []);
        setAssignments(assignmentsResponse?.data?.data || []);
        setCases(casesResponse?.data?.data || []);
      } catch (error) {
        console.error('Failed to fetch data', error);
        // Handle error (e.g., show an error message)
      }
    };

    fetchData();
  }, [token, role, dispatch]);

  return (
    <div className="w-full pb-12">
      <TitleBlok
        textItem={'My services'}
        className={'text-base font-semibold'}
        src="/PhoneIconEmployee.svg"
        classNameTitleBlock="sm:m-8 mx-4 my-6"
      />
      <div className="grid md:grid-cols-2 sm:gap-6 sm:m-8 mx-4 my-6 gap-2 overflow-x-auto max-h-[400px]">
        {services.map((service, index) => (
          <div key={index} className="grow">
            <DashboardCardEmployee texts={service?.positions} title={service?.name} />
          </div>
        ))}
      </div>
      <TitleBlok
        textItem={'My Assignments'}
        className={'text-base font-semibold'}
        src="/HomeIconEmployee.svg"
        classNameTitleBlock="sm:m-8 mx-4 my-6"
      />
      <div>
        <div className="px-4 py-1 mt-4 sm:m-8 mx-4 my-6 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableEmployeesAdmin
                tbody={assignments}
                columns={columnsDashboardEmployee}
                maxHeight='500px'
              />
            </div>
          </div>
        </div>
      </div>
      <TitleBlok
        textItem={'My Cases'}
        className={'text-base font-semibold'}
        src="/HomeIconEmployee.svg"
        classNameTitleBlock="sm:m-8 mx-4 my-6"
      />
      <div>
        <div className="px-4 py-1 mt-4 sm:m-8 mx-4 my-6 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableClients
                tbody={cases}
                columns={columnsDashboardEmployeeCase}
                maxHeight='500px'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
