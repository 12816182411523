import classNames from "../../utils/utils";
import Button from "../Button/Button";


export default function TitleBlockEmployeesAdmin({ textItem, className, classNameTitleBlock, src = "/TitleBlokIcon.svg", AllEmployeesData, setDataEmployeeCurrent, setIsOpen, }) {

    return (

        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className="flex xl:flex-row flex-col items-center gap-2  w-full pr-2">
                <img src="/TitleBlokIcon.svg" alt="img" />
                <p className="xl:w-1/2 w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                {AllEmployeesData?.length != 0 && <div className="w-full flex xl:flex-row flex-col xl:justify-end justify-center gap-2">
                    <div className="w-full">
                        <Button buttonText={"Add Employee"} className="text-white md:text-sm w-full" onClick={()=>setIsOpen(true)} />
                    </div>

                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={(e) => setDataEmployeeCurrent(e.target.value)}
                        >
                            {AllEmployeesData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>
                </div>}
            </div >

        </div >
    )
}