import { useState } from 'react';
import InputLabel from '../../../components/InputLabel/InputLabel';
import Select from '../../../components/Select/Select';
import OptionsFieldArray from './OptionsFieldArray';

export default function FormFields({ register, errors, index, FieldsRemove, onClickAddNewField, control, setValue }) {
  const [currentType, setCurrentType] = useState('input');

  const onChangeType = e => {
    setCurrentType(e?.target?.value);
    e?.target?.value === 'checkbox'
      ? setValue(`fields.${index}.options`, [{}])
      : setValue(`fields.${index}.options`, null);
  };

  return (
    <>
      <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-baseline">
        <div className="mt-5">
          <InputLabel
            classNameInput="flex flex-col"
            classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
            className="w-full border p-[7px]"
            labelTitle={'Field Name'}
            placeholder={'Field Name'}
            register={register}
            name={`fields.${index}.label`}
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.fields?.[index]?.label?.message}</p>}
        </div>
        <div className="w-full bg-white">
          <Select
            register={register}
            title={'Field Type'}
            selectData={[
              { id: 'input', name: 'Input' },
              { id: 'date', name: 'Date' },
              { id: 'checkbox', name: 'Checkbox' },
            ]}
            selectClass="flex-col gap-2"
            name={`fields.${index}.type`}
            onChange={onChangeType}
            className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.fields?.[index]?.type?.message}</p>}
        </div>
        {currentType === 'checkbox' && (
          <OptionsFieldArray register={register} errors={errors} control={control} index={index} />
        )}
      </div>
      <div className="grid  grid-cols-1 sm:gap-6 gap-2 mt-5">
        <section className="text-end">
          <button
            className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
            type="button"
            onClick={onClickAddNewField}>
            Add New Field
          </button>
        </section>
      </div>
      {index !== 0 && (
        <div className="grid  mt-5 grid-cols-1 sm:gap-6 gap-2">
          <section className="text-end ">
            <button
              onClick={() => {
                FieldsRemove(index);
              }}
              className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
              type="button">
              Remove Field
            </button>
          </section>
        </div>
      )}
    </>
  );
}
