import * as yup from "yup";



export const RequestErrorCreateRequestForm = () => {

    const formSchemaForOptions = {
        name:yup.string().required('This field is required')
    }

    const formSchemaForFields = {
        label: yup.string().required('This field is required'),
        type: yup.string().required('This field is required'),
        // options:yup.array().of(yup.object().shape(formSchemaForOptions))
      };

    return (
        yup.object().shape({
            title: yup.string().required("This field is required"),
            fields:yup.array().of(yup.object().shape(formSchemaForFields))
        })
    )
}

export default RequestErrorCreateRequestForm


