import classNames, { onlyNumbers } from "../../utils/utils";
import  debounce  from "debounce";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

export default function TitleBlockInvoice({ textItem, children, className, classNameTitleBlock, setDateId, src = "/TitleBlokIcon.svg", AllEmployeesData, setDataEmployeeCurrent,setCurrentPage,AllClientsData,setDataClientCurrent,setDataPublishCurrent,setDataSentCurrent,setIsOpenCreateModal ,setIsOpenSendModal,setIsOpenPublishModal}) {

    const debouncedOnChange = debounce((e) => {
        setDateId(e?.target?.value)
        setCurrentPage(1)
    }, 500);

    const onChangeEmployees = (e) => {
        setDataEmployeeCurrent(e.target.value)
        setCurrentPage(1)
    }
    const handleChangeClient = (e) => {
        setDataClientCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    };
    const onChangePublish = (e) => {
        setDataPublishCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    };

    const onChangeSent = (e) => {
        setDataSentCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    }

    const navigate = useNavigate()

    const PublishedData = [{name:"All ",id:""},{name:"Published",id:1},{name:"Unpublished",id:0}]
    const SentData = [{name:"All ",id:""},{name:"Sent",id:1},{name:"Not Sent",id:0}]

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={AllEmployeesData?.length ? "flex lg:flex-row flex-col items-center gap-2  w-full pr-2" : "flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2"}>
                <img src="/TitleBlokIcon.svg" alt="img"/>
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                
                <div className="w-full">
                    <Button buttonText={"Create Invoice"} className="text-white md:text-sm w-full" onClick={() => setIsOpenCreateModal(true)}/>
                </div>
                <div className="w-full">
                    <Button buttonText={"Send Invoice"} className="text-white md:text-sm w-full" onClick={() => setIsOpenSendModal(true)}/>
                </div>
                <div className="w-full">
                    <Button buttonText={"Publish Invoices"} className="text-white md:text-sm w-full" onClick={() => setIsOpenPublishModal(true)}/>
                </div>
                <div className="w-full">
                    <Button buttonText={" Export Invoices"} className="text-white md:text-sm w-full" onClick={() => navigate("/Admin/ExportInvoices")}/>
                </div>

                <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <img src="/search.svg" alt="img"/>
                    </div>
                    <input onKeyPress={onlyNumbers} placeholder="search by id" onChange={debouncedOnChange} className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]" />
                </div>

                {AllEmployeesData?.length != 0 &&
                        <div className="w-full">
                            <select
                                id="location"
                                className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                                // value={value}
                                onChange={onChangeEmployees}
                            >
                                {AllEmployeesData?.map((e, index) => (
                                    <option key={index}
                                        // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                        value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                                ))}
                            </select>
                        </div>}
                        { SentData?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangeSent}
                        >
                            {SentData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
                    {PublishedData?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangePublish}
                        >
                            {PublishedData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
                    {AllClientsData?.length &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={handleChangeClient}
                        >
                            {AllClientsData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name}</option>
                            ))}
                        </select>
                    </div>}
            </div>
            {children}
        </div >
    )
}
