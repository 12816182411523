import { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";

import CancleAdd from "../../components/CancleAdd/CancleAdd";
import Title from "../../components/Title/Title";
import { useForm,  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel from "../../components/InputLabel/InputLabel";
import { useNavigate } from "react-router-dom";
import SendNotificationDefaultValue from "./SendNotificationDefaultValue";
import RequestErrorSendNotification from "./RequestErrorSendNotification";
import { notifySuccess} from "../../utils/utils";


import "react-toastify/dist/ReactToastify.css";
import ClientHttp from "../../http/clientsHttp/clientsHttp";


export default function SendNotification() {
    const [isLoading, setIsLoading] = useState(false)

    const afterSubmit = () => {
        
        notifySuccess("Notification successfully sent")
        setTimeout(() => {
            navigate('/Admin/SendNotificationDashboard')
        }, 2000)

    }

    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm({
        defaultValues: useMemo(() => SendNotificationDefaultValue(), []),
        resolver: yupResolver(RequestErrorSendNotification()),
    });

    const onSubmit = (data) => {
        setIsLoading(true)
        ClientHttp.sendNotificationLinks(data).then(() => {
            afterSubmit()
        }).catch(err => {
            const errorFromBackend = Object.keys(err?.response?.data?.errors)
            errorFromBackend.forEach((item) => (
                setError(item, { type: "custom", message: err?.response?.data?.errors?.[item] })
            ))
            setIsLoading(false)
        })
    };

    const onClickCancle = () => navigate('/Admin/SendNotificationDashboard')

    useEffect(() => {
        reset(SendNotificationDefaultValue());
    }, [])

    return (
        <div className="sm:p-8 p-4 w-full">
            <div className="w-full bg-white">
                <div className="sm:p-8 p-4">
                    <Title title={'Send Notification'} className="border-b py-1" classNameTitle={"text-2xl font-semibold"} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-4">
                    <div className='grid  sm:grid-cols-1 sm:gap-6 gap-2 sm:px-8 px-4'>
                        <div>
                            <InputLabel labelTitle={"Title"}
                                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={'Title'}
                                name={`title`}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.title?.message}</p>}
                        </div>
                        <div>
                            <div className="w-full flex flex-col gap-2">
                            <label  className={"font-[system-ui] text-[#60657B] opacity-50 text-xs font-normal"}>{"Description"}</label>
                                <textarea rows={7} className="p-[14px] outline-none w-full border" {...register("description")} />
                                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.description?.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-12">
                        <CancleAdd buttonText={"Send"} onClickCancle={onClickCancle} disabled={isLoading} />
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    )
}
